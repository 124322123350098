import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { DatePicker, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { UserContext } from "state-management/user-context";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
const { RangePicker } = DatePicker;

interface CourseFormValues {
  courseName: string;
  file: File | null;
  selectedDuration: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  };
}
interface AddCourseModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddCourseModal: React.FC<AddCourseModalProps> = ({ open, setOpen }) => {
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | null>(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    courseName: Yup.string()
      .required("Course Name is required")
      .min(4, "Minimum 4 characters"),
    file: Yup.mixed()
      .nullable()
      .test("fileType", "File must be a video", (value: File | null) => {
        if (!value) return true;
        return value.type.startsWith("video/mp4");
      })
      .test(
        "fileSize",
        "File size must be less than 100MB",
        (value: File | null) => {
          if (!value) return true;
          return value && value.size <= 100 * 1024 * 1024;
        }
      ),
    selectedDuration: Yup.object()
      .required("Duration is required")
      .shape({
        startDate: Yup.date().nullable().required("Start date is required"),
        endDate: Yup.date().nullable().required("End date is required"),
      }),
  });

  const formik = useFormik<CourseFormValues>({
    initialValues: {
      courseName: "",
      file: null,
      selectedDuration: {
        startDate: null,
        endDate: null,
      },
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!user?._id) {
          message.error("Please Login first");
          navigate("/login");
          return;
        }

        const { courseName, file, selectedDuration } = values;
        const duration = {
          startDate: dayjs(selectedDuration?.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(selectedDuration?.endDate).format("YYYY-MM-DD"),
        };
        const formData = new FormData();
        formData.append("courseName", courseName || "");
        formData.append("duration", JSON.stringify(duration) || "");
        formData.append("file", file || "");

        let res = await apiRequest("addCourse", formData);
        if (res?.status === 200) {
          message.success(res?.data?.message);
          resetForm();
          const savedCourseId = res?.data?.course?._id;
          navigate(`/old-courses/${savedCourseId}`);
        }
      } catch (error: any) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      formik.setFieldValue("file", file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const clearSelectedVideo = () => {
    formik.setFieldValue("file", null);
    setVideoPreviewUrl(null);
    const fileInput = document.getElementById(
      "file"
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <>
      <CustomAntdModal
        formik={formik}
        isOpenModal={open}
        setIsOpenModal={setOpen}
        title="Add New Course"
      >
        <div className="m-4">
          <div className="flex justify-between">
            <div>
              <h6 className="text-md font-bold text-rose-500 mt-2">
                {" "}
                Please Fill the below fields to add a new course
              </h6>
            </div>
            <div></div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
            className="mx-4"
          >
            <div className="flex flex-row flex-wrap items-center justify-between mx-4"></div>
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:p-2 w-full md:w-2/3">
                <label
                  htmlFor="courseName"
                  className="text-md font-bold text-gray-900 mt-2.5"
                >
                  Course Name
                </label>
                <input
                  type="text"
                  id="courseName"
                  name="courseName"
                  value={formik.values.courseName}
                  placeholder="Course Name"
                  onChange={formik.handleChange}
                  className="w-full bg-transparent border p-2 rounded focus:border-none focus:ring-0"
                />
                {formik.touched.courseName && formik.errors.courseName && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.courseName}
                  </div>
                )}
              </div>

              <div className="md:p-2 w-full md:w-1/2">
                <div className="flex flex-col">
                  <label
                    htmlFor="selectedDuration"
                    className="text-md font-bold text-gray-900 mt-1"
                  >
                    Select Duration
                  </label>
                  <div>
                    <RangePicker
                      className="w-full p-2 bg-transparent border rounded focus:border-none focus:ring-0"
                      format="YYYY-MM-DD"
                      value={[
                        formik.values.selectedDuration.startDate,
                        formik.values.selectedDuration.endDate,
                      ]}
                      onChange={(value) => {
                        formik.setFieldValue("selectedDuration", {
                          startDate: value?.[0] || null,
                          endDate: value?.[1] || null,
                        });
                      }}
                    />
                  </div>
                </div>

                {formik.touched.selectedDuration &&
                  formik.errors.selectedDuration && (
                    <div className="text-red-500 text-sm mt-1">
                      {(formik.errors.selectedDuration?.startDate as string) ||
                        (formik.errors.selectedDuration?.endDate as string)}
                    </div>
                  )}
              </div>
            </div>
            <div className="p-2">
              <div className="relative">
                <div className="flex flex-col">
                  <h6 className="text-md font-bold text-gray-900 mt-2.5">
                    Upload Video
                  </h6>
                  <div className="flex flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                    <div className="flex flex-col items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                        />
                      </svg>
                      <button
                        className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                        onClick={(event) => {
                          event.preventDefault();
                          document.getElementById("file")?.click();
                        }}
                      >
                        Upload Video
                      </button>
                      <p>File accepted: MP4 (Max.100MB)</p>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        accept="video/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                      {videoPreviewUrl && (
                        <button
                          className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                          onClick={clearSelectedVideo}
                        >
                          Remove Video
                        </button>
                      )}
                    </div>
                    {videoPreviewUrl && (
                      <div className="flex flex-col items-center gap-2">
                        <video
                          controls
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            maxHeight: "240px",
                            width: "100%",
                          }}
                        >
                          <source src={videoPreviewUrl} type="video/mp4" />
                        </video>
                      </div>
                    )}
                  </div>
                </div>
                {formik.touched.file && formik.errors.file && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.file as string}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </CustomAntdModal>
    </>
  );
};

export default AddCourseModal;
