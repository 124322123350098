import { apiRequest } from "(apis)/api-interface/api-handler";
import { DatePicker, Pagination, Select, Modal } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  allStates,
  allCities,
} from "../marketing-download/cities-provinces-data";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import Loading from "shared-components/ui/Loading";

export default function Page() {
  const [skus, setSkus] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest("getAllSKUs");
        if (response?.data?.allSKUs?.length > 0) {
          const formattedData = response?.data?.allSKUs?.map((item) => {
            return {
              label: item?.SKUName,
              value: item?.SKUName,
            };
          });
          setSkus(formattedData);
        } else {
          setSkus([]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };
    fetchData();
  }, []);

  const [form, setForm] = useState({
    type: "user",
    skus: [],
    city: [],
    province: [],
    a_start_date: new Date("01/01/2024"),
    a_end_date: new Date(),
    order_start_date: new Date("01/01/2024"),
    order_end_date: new Date(),
  });

  const [provinces, setProvinces] = useState(allStates);
  const [cities, setCities] = useState(allCities);

  const TYPES = ["OWN_BUNDLE", "PLANS", "FREE_USERS"];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedComponentsModal, setSelectedComponentsModal] = useState({
    seperateComponents: [],
    featuresPurchased: [],
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedOrdersModal, setSelectedOrdersModal] = useState([]);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);

  const handleGetMarketingDetails = async () => {
    setPage(1);
    setData([]);
    try {
      setLoading(true);
      let formData = form;
      if (formData?.city?.length === 0) {
        delete formData?.city;
      }
      if (formData?.province?.length === 0) {
        delete formData?.province;
      }
      if (formData?.skus?.length === 0) {
        delete formData?.skus;
      }
      if (formData?.a_start_date === new Date("01/01/2024")) {
        delete formData?.a_start_date;
      }
      if (formData?.a_end_date === new Date()) {
        delete formData?.a_end_date;
      }
      if (formData?.order_start_date === new Date("01/01/2024")) {
        delete formData?.order_start_date;
      }
      if (formData?.order_end_date === new Date()) {
        delete formData?.order_end_date;
      }

      const response = await apiRequest("getMarketingDetails", formData);
      if (response?.data?.isSuccess === true) {
        toast.success("Details fetched successfully");
        setData(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const RowsPerPage = 100;
  const [page, setPage] = useState(1);

  //date to string (dd/mm/yyyy)
  const dateToString = (date) => {
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const month = dateObj?.getMonth() + 1;
    const year = dateObj?.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const DownloadCSV = () => {
    // Helper function to escape CSV fields
    const escapeCSVField = (field) => {
      if (field == null) return '';
      // Escape double quotes by doubling them
      const escaped = String(field).replace(/"/g, '""');
      // Wrap in quotes if the field contains commas, quotes, or newlines
      return escaped.includes(',') || escaped.includes('"') || escaped.includes('\n') 
        ? `"${escaped}"` 
        : escaped;
    };

    // Create CSV headers
    const headers = [
      'Email', 
      'Full Name', 
      'Phone Number', 
      'City', 
      'Province', 
      'School/Institute', 
      'Account Creation Date',
      'Total Paid Amount', 
      'Separate Components', 
      'Features Purchased', 
      'Order Dates', 
      'Order Amounts', 
      'Coupon Codes', 
      'Order Statuses'
    ];

    // Generate CSV rows
    const csvRows = data.map((item) => [
      escapeCSVField(item?.username),
      escapeCSVField(item?.fullname),
      escapeCSVField(item?.phonenumber),
      escapeCSVField(item?.city),
      escapeCSVField(dateToString(item?.accountcreateddate)),
      escapeCSVField(item?.province),
      escapeCSVField(item?.school),
      escapeCSVField(item?.totalPaidAmount || 0),
      escapeCSVField(item?.seperateComponents?.map((component) => component?.Access)?.join("; ")),
      escapeCSVField(item?.featuresPurchased?.map((component) => component?.planName)?.join("; ")),
      escapeCSVField(item?.orders?.map((order) => dateToString(order?.CreationDate))?.join("; ")),
      escapeCSVField(item?.orders?.map((order) => order?.PaidAmount)?.join("; ")),
      escapeCSVField(item?.orders?.map((order) => order?.CouponCode || 'N/A')?.join("; ")),
      escapeCSVField(item?.orders?.map((order) => order?.OrderStatus)?.join("; "))
    ]);

    // Combine headers and rows
    const csvContent = [
      headers,
      ...csvRows
    ].map(row => row.join(",")).join("\n");

    // Create and download the file
    const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "marketing_details.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showComponentsModal = (seperateComponents, featuresPurchased) => {
    setSelectedComponentsModal({
      seperateComponents,
      featuresPurchased,
    });
    setIsModalVisible(true);
  };

  const showOrdersModal = (orders) => {
    setSelectedOrdersModal(orders);
    setIsOrderModalVisible(true);
  };

  return (
    <div className="w-[95%] mx-auto">
      <h1>User Details</h1>
      <div className="grid grid-cols-2 gap-2">
        <Select
          options={TYPES?.map((type) => ({
            label: type,
            value: type,
          }))}
          onChange={(value) => setForm({ ...form, type: value })}
          placeholder="Select Type"
          bordered={false}
          style={{ padding: "5px" }}
          className="rounded border border-gray-300 !p-5 !px-0"
          allowClear
        />

        {form?.type === "PLANS" && (
          <Select
            options={skus}
            onChange={(value) => setForm({ ...form, skus: value })}
            placeholder="Select SKUs"
            mode="multiple"
            bordered={false}
            className="rounded border border-gray-300 !p-1 !px-0"
            allowClear
          />
        )}
        <Select
          options={provinces?.map((province) => ({
            label: province?.name,
            value: province?.name,
          }))}
          onChange={(value) => setForm({ ...form, province: value })}
          placeholder="Select Province"
          mode="multiple"
          bordered={false}
          style={{ padding: "5px" }}
          className="rounded border border-gray-300"
          allowClear
        />
        <Select
          options={cities?.map((city) => ({
            label: city.name,
            value: city.name,
          }))}
          onChange={(value) => setForm({ ...form, city: value })}
          placeholder="Select City"
          mode="multiple"
          bordered={false}
          style={{ padding: "5px" }}
          className="rounded border border-gray-300"
          allowClear
        />
        {form?.type !== "PLANS" && <div></div>}
        <DatePicker
          onChange={(value) =>
            setForm({ ...form, a_start_date: value?.toDate() })
          }
          placeholder="Select Account Start Date"
          style={{ padding: "5px" }}
          className="rounded !border border-gray-300"
          allowClear
        />
        <DatePicker
          onChange={(value) =>
            setForm({ ...form, a_end_date: value?.toDate() })
          }
          placeholder="Select Account End Date"
          style={{ padding: "5px" }}
          className="rounded !border border-gray-300"
          allowClear
        />
        <DatePicker
          onChange={(value) =>
            setForm({ ...form, order_start_date: value?.toDate() })
          }
          placeholder="Select Order Start Date"
          style={{ padding: "5px" }}
          className="rounded !border border-gray-300"
          allowClear
        />
        <DatePicker
          onChange={(value) =>
            setForm({ ...form, order_end_date: value?.toDate() })
          }
          placeholder="Select Order End Date"
          style={{ padding: "5px" }}
          className="rounded !border border-gray-300"
          allowClear
        />
      </div>
      <div className="flex justify-end mt-5 gap-2">
        <ButtonFill handleClick={DownloadCSV}>Download CSV</ButtonFill>

        <ButtonFill handleClick={handleGetMarketingDetails}>
          Get Details
        </ButtonFill>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <table className="w-full mb-20 text-sm text-left text-gray-500 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 overflow-x-auto">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Email
              </th>
              <th scope="col" className="px-4 py-4">
                Full Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Phone Number
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                City
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Province
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                School/Institute
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Total Paid Amount
              </th>
              {/* <th scope="col" className="px-4 py-4 text-center">
                OWN BUNDLE PRODUCTS
              </th> */}
              <th scope="col" className="px-4 py-4 text-center">
                PRODUCTS
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Orders Details
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.slice((page - 1) * RowsPerPage, page * RowsPerPage)
              ?.map((item, index) => (
                <tr className="border-b" key={index}>
                  <td className="px-4 py-3">{item?.username} </td>
                  <td className="px-4 py-3">{item?.fullname} </td>
                  <td className="px-4 py-3">{item?.phonenumber} </td>
                  <td className="px-4 py-3">{item?.city} </td>
                  <td className="px-4 py-3">{item?.province} </td>
                  <td className="px-4 py-3">{item?.school} </td>
                  <td className="px-4 py-3">{item?.totalPaidAmount || 0} </td>
                  {/* <td className="px-4 py-3">
                    <div className="flex flex-col gap-2">
                      {item?.seperateComponents?.map((component) => (
                        <div className="flex flex-row gap-2">
                          <p>{component?.Access}</p>
                        </div>
                      )) || "N/A"}{" "}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex flex-col gap-2">
                      {item?.featuresPurchased?.map((component) => (
                        <div className="flex flex-row gap-2">
                          <p>{component?.planName}</p>
                        </div>
                      )) || "N/A"}{" "}
                    </div>
                  </td> */}
                  {/* <td className="px-4 py-3">
                    <div className="flex flex-col gap-2">
                      {item?.orders?.map((order) => {
                        return (
                          <div className="flex flex-row gap-2">
                            <p>{dateToString(order?.CreationDate)}</p>
                            <p>{order?.PaidAmount}</p>
                            <p>{order?.CouponCode}</p>
                            <p>{order?.OrderStatus}</p>
                          </div>
                        );
                      })}
                    </div>
                  </td> */}
                  <td className="px-4 py-3 text-xs">
                    <button className="text-xs bg-primary text-white px-2 py-1 rounded font-bold hover:scale-105 transition-all duration-300 cursor-pointer"
                      onClick={() => showComponentsModal(
                        item?.seperateComponents, 
                        item?.featuresPurchased
                      )}
                    >
                      View Products
                    </button>
                  </td>
                  <td className="px-4 py-3 text-xs">
                    <button className="text-xs bg-primary text-white px-2 py-1 rounded font-bold hover:scale-105 transition-all duration-300 cursor-pointer"
                      onClick={() => showOrdersModal(item?.orders)}
                    >
                      View Orders
                    </button>
                  </td>
                </tr>
              ))}
            <div className="flex justify-center items-center mt-5">
              {data?.length > RowsPerPage && (
                <Pagination
                  current={page}
                  onChange={(value) => setPage(value)}
                  total={data?.length}
                  pageSize={RowsPerPage}
                />
              )}
            </div>
          </tbody>
        </table>
      )}

      <Modal
        title="Components Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div>
          <h3 className="text-lg font-bold">My Own Bundle Products</h3>
          {selectedComponentsModal.seperateComponents?.length > 0 ? (
            <ul>
              {selectedComponentsModal.seperateComponents.map((component, index) => (
                <li key={index}>{component?.Access}</li>
              ))}
            </ul>
          ) : (
            <p>No separate components</p>
          )}

          <h3 className="mt-4 text-lg font-bold">Features Purchased</h3>
          {selectedComponentsModal.featuresPurchased?.length > 0 ? (
            <ul>
              {selectedComponentsModal.featuresPurchased.map((component, index) => (
                <li key={index}>{component?.planName}</li>
              ))}
            </ul>
          ) : (
            <p>No features purchased</p>
          )}
        </div>
      </Modal>

      <Modal
        title="Order Details"
        visible={isOrderModalVisible}
        onCancel={() => setIsOrderModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="overflow-x-auto">
          {selectedOrdersModal?.length > 0 ? (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th className="px-4 py-2">Creation Date</th>
                  <th className="px-4 py-2">Paid Amount</th>
                  <th className="px-4 py-2">Coupon Code</th>
                  <th className="px-4 py-2">Order Status</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrdersModal.map((order, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">
                      {dateToString(order?.CreationDate)}
                    </td>
                    <td className="px-4 py-2">{order?.PaidAmount}</td>
                    <td className="px-4 py-2">{order?.CouponCode || 'N/A'}</td>
                    <td className="px-4 py-2">{order?.OrderStatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Modal>
    </div>
  );
}

