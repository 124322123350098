import React, { useEffect, useState, useMemo } from "react";
import Loading from "../../shared-components/ui/Loading";
import toast, { Toaster } from "react-hot-toast";
import TopBar from "./components/top-bar";
import { UserAPI } from "(apis)/(shared-apis)/user-apis";
import OrderHistory from "./components/orders";
import { Minus, Plus, Trash2 } from "lucide-react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Select } from "antd";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import { PackagesAPI } from "(apis)/(shared-apis)/packages-apis";

export default function Page() {
  const [user, setUser] = React.useState(null);
  const [email, setEmail] = React.useState<string>("");

  const [loading, setLoading] = React.useState(false);


  const [bundles, setBundles] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [ownBundles, setOwnBundles] = React.useState([]);


  const FormatDate = (date: string) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [fetchUser, setFetchUser] = React.useState(false);

  const [distinctTags, setDistinctTags] = React.useState([]);

  const getDistinctTags = async () => {
    const response = await apiRequest("getDistintTags", []);
    setDistinctTags(response.data.tags);
  }


  const [allPackageSkus, setAllPackageSkus] = React.useState([]);
  const getAllPackageSkus = async () => {
    try {
      const response = await PackagesAPI("getAllPackageSkus", []);
      if (response.data.success) {
        setAllPackageSkus(response.data.skus);
      } else {
        toast.error("Failed to get all package skus");
      }
    } catch (error) {
      toast.error("Failed to get all package skus");
    }
  }

  const [coursesAndSKUs, setCoursesAndSKUs] = React.useState([]);
  const getCoursesAndSKUs = async () => {
    try {
      const response = await PackagesAPI("getCoursesAndSKUs", [""]);
      console.log(response.data)

      if (response.data.success === true) {
        setCoursesAndSKUs(response.data.courses);
      } else {
        toast.error("Failed to get courses and skus");
      }
    } catch (error) {
      toast.error("Failed to get courses and skus");
    }
  }


  useEffect(() => {
    getDistinctTags();
    getAllPackageSkus();
    getCoursesAndSKUs();
  }, []);

  const [updateBundlesLoading, setUpdateBundlesLoading] = React.useState(false);
  const UpdateBundles = async ({ type }: { type: string }) => {
    setUpdateBundlesLoading(true);
    try {
      const payload = {
        data: type === "bundles" ? bundles : type === "own-bundles" ? ownBundles : courses,
        type: type,
        email: email
      }
      const response = await UserAPI("updateBundles", [""], payload);
      if (response.status === 200) {
        toast.success("Bundles updated successfully");
      } else {
        toast.error("Failed to update bundles");
      }
    } catch (error) {
      toast.error("Failed to update bundles");
    }
    setUpdateBundlesLoading(false);
  }

  const [addbundleform, setAddbundleform] = React.useState({
    startDate: "",
    endDate: "",
    access: "",
  });

  const [addBundleForm, setAddBundleForm] = React.useState({
    planId: "",
    planName: "",
    features: [],
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    entityPart: "Manual Access",
  });

  const [addCourseForm, setAddCourseForm] = React.useState({
    course_name: "",
    course_id: "",
    sku_Id: "",
    sku_name: "",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    type: "OLD COURSE PURCHASE",
    status: "Active",
    tag: "Manual Access",
  });

  const options = useMemo(() => {
    return allPackageSkus
      .filter((sku: any) => !bundles.some((b: any) => b.planName === sku.planName))
      .map((sku: any, index: number) => (
        <Select.Option key={index} value={sku.planId}>{sku.boardName} - {sku.planName}</Select.Option>
      ));
  }, [allPackageSkus, bundles]);

  return (
    <div>
      <div className="w-full md:w-[95%] mx-auto h-full">
        <h1 className="text-3xl text-gray-700 font-bold">User Manager</h1>
        <Toaster />

        <TopBar
          loading={loading}
          email={email}
          setEmail={setEmail}
          setUser={setUser}
          setLoading={setLoading}
          setFetchUser={setFetchUser}
          setBundles={setBundles}
          setCourses={setCourses}
          setOwnBundles={setOwnBundles}
        />


        {loading ? <Loading /> : null}

        {user ? (
          <div className="mt-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <AccountDetails
                user={user}
                loading={loading}
                FormatDate={FormatDate}
              />
              <OrderHistory
                email={email}
                fetchUser={fetchUser}
              />
            </div>


            <div className="mt-5 border border-gray-300 rounded p-5">
              <div className="flex justify-between items-center">
                <h1 className="text-xl text-gray-700 font-bold mb-5">
                  Bundles
                </h1>
                <ButtonFill
                  handleClick={() => UpdateBundles({ type: "bundles" })}
                  isLoading={updateBundlesLoading}
                >
                  Update Bundles
                </ButtonFill>

              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                {bundles && bundles.length > 0 && bundles.map((bundle: any) => (
                  <div key={bundle?.planId} className="flex flex-col justify-between border border-gray-300 rounded p-5 gap-2 relative">

                    <div className="absolute top-2 right-2">
                      <button className="bg-primary rounded-lg shadow-2xl text-sm flex items-center gap-2 text-white p-1 md:p-2 font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                          setBundles(bundles.filter((b: any) => b.planId !== bundle.planId));
                        }}
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>


                    <div className="flex flex-col gap-2 justify-start items-start">
                      <h2 className="text-lg text-gray-700 font-bold">{bundle?.planName}</h2>
                      <div className="flex flex-wrap gap-2">
                        {bundle?.features?.map((feature: string) => (
                          <div className="flex border border-gray-300 rounded p-1 gap-2 items-center">
                            <h2 className="text-gray-700">{feature}</h2>
                            <Minus className="text-gray-700 w-4 h-4 cursor-pointer hover:text-rose-500 transition duration-300 ease-in-out"
                              onClick={() => {
                                const updatedBundles = bundles.map((b: any) => {
                                  if (b.planId === bundle.planId) {
                                    return { ...b, features: b.features.filter((f: string) => f !== feature) };
                                  }
                                  return b;
                                });
                                setBundles(updatedBundles);
                              }}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="flex justify-between flex-wrap w-full items-center mt-5">
                        <div className="w-full">
                          <h2 className="text-gray-700 font-bold">Add Feature</h2>
                        </div>
                        <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                          bordered={false}
                          showSearch
                          placeholder="Select Feature"
                          onChange={(value) => {
                            const updatedBundles = bundles.map((b: any) => {
                              if (b.planId === bundle.planId) {
                                return { ...b, features: [...b.features, value] };
                              }
                              return b;
                            });
                            setBundles(updatedBundles);
                          }}
                        >
                          {distinctTags && distinctTags.length > 0 && distinctTags
                            .filter((tag: any) => !bundle.features.includes(tag))
                            .map((tag: any) => (
                              <Select.Option value={tag}>{tag}</Select.Option>
                            ))}
                        </Select>
                      </div>

                    </div>
                    <div className="flex justify-between gap-2 flex-wrap">
                      <h2><span className="font-bold mr-2">Start Date:</span>
                        <input
                          type="date"
                          value={new Date(bundle?.subscriptionStartDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedBundles = bundles.map((b: any) => {
                              if (b.planId === bundle.planId) {
                                //do it in format 2024-07-22T03:35:25.125Z
                                return { ...b, subscriptionStartDate: new Date(e.target.value).toISOString() };
                              }
                              return b;
                            });
                            setBundles(updatedBundles);
                          }}
                        />
                      </h2>
                      <h2><span className="font-bold mr-2">End Date:</span>
                        <input
                          type="date"
                          value={new Date(bundle?.subscriptionEndDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedBundles = bundles.map((b: any) => {
                              if (b.planId === bundle.planId) {
                                return { ...b, subscriptionEndDate: new Date(e.target.value).toISOString() };
                              }
                              return b;
                            });
                            setBundles(updatedBundles);
                          }}
                        />
                      </h2>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col justify-between border-2 border-dashed border-gray-300 rounded p-5 gap-2 relative bg-gray-50 mt-5">
                <h2 className="text-lg text-gray-700 font-bold">Add Bundle</h2>
                <div className="flex justify-between w-full gap-2 items-center flex-wrap">
                  <h2 ><span className="font-bold mr-2">SKU Name:</span>
                    <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                      style={{ width: "18rem" }}
                      bordered={false}
                      showSearch
                      placeholder="Select SKU"
                      onChange={(value) => {
                        const sku = allPackageSkus.find((sku: any) => sku.planId === value);
                        setAddBundleForm({ ...addBundleForm, planId: value, planName: sku?.planName, features: sku?.access });
                      }}
                      value={addBundleForm.planId}
                    >
                      {options}
                    </Select>
                  </h2>
                  <h2 ><span className="font-bold mr-2">
                    Start Date:
                  </span>
                    <input type="date" className="border border-gray-300 rounded p-1" value={addBundleForm.subscriptionStartDate} onChange={(e) => setAddBundleForm({ ...addBundleForm, subscriptionStartDate: e.target.value })} />
                  </h2>
                  <h2 ><span className="font-bold mr-2">
                    End Date:
                  </span>
                    <input type="date" className="border border-gray-300 rounded p-1" value={addBundleForm.subscriptionEndDate} onChange={(e) => setAddBundleForm({ ...addBundleForm, subscriptionEndDate: e.target.value })} />
                  </h2>
                  <ButtonFill
                    handleClick={() => {
                      if (!addBundleForm.planId || !addBundleForm.subscriptionStartDate || !addBundleForm.subscriptionEndDate || !addBundleForm.features || !addBundleForm.planName) {
                        toast.error("Please fill all fields");
                        return;
                      }
                      const Added = {
                        planId: addBundleForm.planId,
                        planName: addBundleForm.planName,
                        features: addBundleForm.features,
                        subscriptionStartDate: new Date(addBundleForm.subscriptionStartDate).toISOString(),
                        subscriptionEndDate: new Date(addBundleForm.subscriptionEndDate).toISOString(),
                        entityPart: "Manual Access",
                        categoryPart: "PRE-MEDICAL"
                      }
                      setBundles([...bundles, Added]);
                      setAddBundleForm({ planId: "", planName: "", features: [], subscriptionStartDate: "", subscriptionEndDate: "", entityPart: "Manual Access" });
                    }}
                    isLoading={updateBundlesLoading}
                  >
                    Add Bundle
                  </ButtonFill>
                </div>
              </div>

            </div>


            <div className="mt-5 border border-gray-300 rounded p-5">
              <div className="flex justify-between items-center">
                <h1 className="text-xl text-gray-700 font-bold mb-5">
                  Own Bundles
                </h1>
                <ButtonFill
                  handleClick={() => UpdateBundles({ type: "own-bundles" })}
                  isLoading={updateBundlesLoading}
                >
                  Update Own Bundles
                </ButtonFill>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                {ownBundles && ownBundles.length > 0 && ownBundles.map((bundle: any, index: number) => (
                  <div key={index} className="flex flex-col justify-between border border-gray-300 rounded p-5 gap-2 relative">
                    <div className="absolute top-2 right-2">
                      <button className="bg-primary rounded-lg shadow-2xl text-sm flex items-center gap-2 text-white p-1 md:p-2 font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                          setOwnBundles(ownBundles.filter((b: any) => b.Access !== bundle.Access));
                        }}
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>

                    <h2 className="text-lg text-gray-700 font-bold">{bundle?.Access}</h2>
                    <div className="flex justify-between gap-2">
                      <h2><span className="font-bold mr-2">Start Date:</span>
                        <input
                          type="date"
                          value={new Date(bundle?.SubscriptionStartDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedBundles = ownBundles.map((b: any) => {
                              if (b.Access === bundle.Access) {
                                //do it in format 2024-07-22T03:35:25.125Z
                                return { ...b, SubscriptionStartDate: new Date(e.target.value).toISOString() };
                              }
                              return b;
                            });
                            setOwnBundles(updatedBundles);
                          }}
                        />
                      </h2>
                      <h2><span className="font-bold mr-2">End Date:</span>
                        <input
                          type="date"
                          value={new Date(bundle?.SubscriptionEndDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedBundles = ownBundles.map((b: any) => {
                              if (b.Access === bundle.Access) {
                                return { ...b, SubscriptionEndDate: new Date(e.target.value).toISOString() };
                              }
                              return b;
                            });
                            setOwnBundles(updatedBundles);
                          }}
                        />
                      </h2>
                    </div>

                  </div>
                ))}
              </div>
              <div className="flex flex-col justify-between border-2 border-dashed border-gray-300 rounded p-5 gap-2 relative bg-gray-50 mt-5">
                <h2 className="text-lg text-gray-700 font-bold">Add Own Bundle</h2>
                <div className="flex justify-between flex-wrap w-full gap-2 items-center">
                  <h2 ><span className="font-bold mr-2">Access:</span>
                    <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                      bordered={false}
                      showSearch
                      placeholder="Select Access"
                      onChange={(value) => setAddbundleform({ ...addbundleform, access: value })}
                      value={addbundleform.access}
                    >
                      {distinctTags && distinctTags.length > 0 && distinctTags
                        .filter((tag: any) => !ownBundles.some((b: any) => b.Access === tag))
                        .map((tag: any) => (
                          <Select.Option value={tag}>{tag}</Select.Option>
                        ))}
                    </Select>
                  </h2>
                  <h2><span className="font-bold mr-2">Start Date:</span>
                    <input type="date" className="border border-gray-300 rounded p-1" value={addbundleform.startDate} onChange={(e) => setAddbundleform({ ...addbundleform, startDate: e.target.value })} />
                  </h2>
                  <h2><span className="font-bold mr-2">End Date:</span>
                    <input type="date" className="border border-gray-300 rounded p-1" value={addbundleform.endDate} onChange={(e) => setAddbundleform({ ...addbundleform, endDate: e.target.value })} />
                  </h2>
                </div>
                <div className="flex justify-end mt-5">
                  <ButtonFill
                    handleClick={() => {
                      if (!addbundleform.access || !addbundleform.startDate || !addbundleform.endDate) {
                        toast.error("Please fill all fields");
                        return;
                      }
                      const Added = {
                        Access: addbundleform.access,
                        SubscriptionStartDate: new Date(addbundleform.startDate).toISOString(),
                        SubscriptionEndDate: new Date(addbundleform.endDate).toISOString(),
                      }
                      setOwnBundles([...ownBundles, Added]);
                      setAddbundleform({ startDate: "", endDate: "", access: "" });
                    }}
                    isLoading={updateBundlesLoading}
                  >
                    Add Own Bundle
                  </ButtonFill>
                </div>
              </div>
            </div>


            <div className="mt-5 border border-gray-300 rounded p-5">
              <div className="flex justify-between items-center">
                <h1 className="text-xl text-gray-700 font-bold mb-5">
                  Courses
                </h1>
                <ButtonFill
                  handleClick={() => UpdateBundles({ type: "courses" })}
                  isLoading={updateBundlesLoading}
                >
                  Update Courses
                </ButtonFill>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                {courses && courses.length > 0 && courses.map((course: any) => (
                  <div key={course?.courseId} className="flex flex-col justify-between border border-gray-300 rounded p-5 gap-2 relative">
                    <h2 className="text-lg text-gray-700 font-bold">Course: {course?.course_name}</h2>
                    <h2 className="text-lg text-gray-700 font-bold">SKU Name: {course?.sku_name}</h2>
                    <div className="flex flex-col md:flex-row justify-between gap-2">
                      <h2><span className="font-bold mr-2">Type:</span>
                       <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                        bordered={false}
                        showSearch
                        placeholder="Select Type"
                        onChange={(value) => {
                          const updatedCourses = courses.map((c: any) => {
                            if (c.courseId === course.courseId) {
                              return { ...c, type: value };
                            }
                            return c;
                          });
                          setCourses(updatedCourses);
                        }}
                        value={course?.type}
                      >
                        <Select.Option value="OLD COURSE PURCHASE">OLD COURSE PURCHASE</Select.Option>
                        <Select.Option value="NEW COURSE PURCHASE">NEW COURSE PURCHASE</Select.Option>
                      </Select>
                      </h2>
                      <h2><span className="font-bold mr-2">Start Date:</span>
                        <input
                          type="date"
                          value={new Date(course?.subscriptionStartDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedCourses = courses.map((c: any) => {
                              if (c.courseId === course.courseId) {
                                return { ...c, subscriptionStartDate: new Date(e.target.value).toISOString() };
                              }
                              return c;
                            });
                            setCourses(updatedCourses);
                          }}
                        />
                      </h2>                      
                      <h2><span className="font-bold mr-2">End Date:</span>
                        <input
                          type="date"
                          value={new Date(course?.subscriptionEndDate)?.toISOString().split('T')[0]}
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) => {
                            const updatedCourses = courses.map((c: any) => {
                              if (c.courseId === course.courseId) {
                                return { ...c, subscriptionEndDate: new Date(e.target.value).toISOString() };
                              }
                              return c;
                            });
                            setCourses(updatedCourses);
                          }}
                        />
                      </h2>
                    </div>
                    {/* delete course */}
                      <button className="bg-primary rounded-lg shadow-2xl text-sm flex items-center gap-2 text-white p-1 md:p-2 font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed absolute top-2 right-2"
                      onClick={() => {
                        setCourses(courses.filter((c: any) => c.courseId !== course.courseId));
                      }}
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-between border-2 border-dashed border-gray-300 rounded p-5 gap-2 relative bg-gray-50 mt-5">
              <h2 className="text-lg text-gray-700 font-bold">Add Courses</h2>
              <div className="flex justify-between flex-wrap w-full gap-2 items-center">
                <h2 ><span className="font-bold mr-2">Select Course:</span>
                  <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                    bordered={false}
                    showSearch
                    placeholder="Select Course"
                    onChange={(value) => {
                      const course = coursesAndSKUs.find((course: any) => course._id === value);
                      setAddCourseForm({
                        ...addCourseForm,
                        course_id: course._id,
                        course_name: course.courseName,
                        sku_Id: "",
                        sku_name: "",
                      });
                    }}
                    value={addCourseForm.course_id}
                  >
                    {coursesAndSKUs && coursesAndSKUs.length > 0 && coursesAndSKUs.map((course: any) => (
                      <Select.Option value={course._id}>{course.courseName}</Select.Option>
                    ))}
                  </Select>
                </h2>
                {/* list skus of selected course */}
                <h2 ><span className="font-bold mr-2">Select SKU:</span>
                  <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                    bordered={false}
                    showSearch
                    placeholder="Select SKU"
                    onChange={(value) => {
                      const sku = coursesAndSKUs.find((course: any) => course._id === addCourseForm.course_id)?.pricings.find((sku: any) => sku._id === value);
                      setAddCourseForm({ ...addCourseForm,                      
                        sku_Id: sku._id,
                        sku_name: sku.SKUName,
                      });
                    }}
                    value={addCourseForm.sku_Id}
                  >
                    {coursesAndSKUs && coursesAndSKUs.length > 0 && coursesAndSKUs.find((course: any) => course._id === addCourseForm.course_id)?.pricings.map((sku: any) => (
                      <Select.Option value={sku._id}>{sku.SKUName}</Select.Option>
                    ))}
                  </Select>
                </h2>
                <h2 ><span className="font-bold mr-2">Select Type:</span>
                  <Select className="bg-white p-2 border border-gray-300 rounded w-full"
                    bordered={false}
                    showSearch
                    placeholder="Select Type"
                    onChange={(value) => setAddCourseForm({ ...addCourseForm, type: value })}
                    value={addCourseForm.type}
                  >
                    <Select.Option value="OLD COURSE PURCHASE">OLD COURSE PURCHASE</Select.Option>
                    <Select.Option value="NEW COURSE PURCHASE">NEW COURSE PURCHASE</Select.Option>
                  </Select>
                </h2>
                <h2 className="flex flex-col justify-between gap-2"><span className="font-bold mr-2">Start Date:</span>
                  <input type="date" className="border border-gray-300 rounded p-1" value={addCourseForm.subscriptionStartDate} onChange={(e) => setAddCourseForm({ ...addCourseForm, subscriptionStartDate: e.target.value })} />
                </h2>
                <h2 className="flex flex-col justify-between gap-2"><span className="font-bold mr-2">End Date:</span>
                  <input type="date" className="border border-gray-300 rounded p-1" value={addCourseForm.subscriptionEndDate} onChange={(e) => setAddCourseForm({ ...addCourseForm, subscriptionEndDate: e.target.value })} />
                </h2>
                <ButtonFill
                  handleClick={() => {
                    if (!addCourseForm.course_id || !addCourseForm.sku_Id || !addCourseForm.subscriptionStartDate || !addCourseForm.subscriptionEndDate) {
                      toast.error("Please fill all fields");
                      return;
                    }
                    //if course is already in courses, then give error
                    if (courses.some((c: any) => c.course_id === addCourseForm.course_id)) {
                      toast.error("Course already exists");
                      return;
                    }
                    const Added = {
                      course_id: addCourseForm.course_id,
                      sku_Id: addCourseForm.sku_Id,
                      course_name: addCourseForm.course_name,
                      sku_name: addCourseForm.sku_name,
                      subscriptionStartDate: new Date(addCourseForm.subscriptionStartDate).toISOString(),
                      subscriptionEndDate: new Date(addCourseForm.subscriptionEndDate).toISOString(),
                      type: "OLD COURSE PURCHASE",
                      status: "Active",
                      tag: "Manual Access"
                    }

                    setCourses([...courses, Added]);
                    setAddCourseForm({
                      course_id: "",
                      course_name: "",
                      sku_Id: "",
                      sku_name: "",
                      subscriptionStartDate: "",
                      subscriptionEndDate: "",
                      type: "",
                      status: "",
                      tag: ""
                    });
                  }}
                >
                  Add Course
                </ButtonFill>
              </div>
            </div>




          </div>
        ) : (
          <div className="mt-5">No user found</div>
        )}


      </div>

    </div>
  );
}

const AccountDetails = ({ user, loading, FormatDate }) => {

  const [password, setPassword] = React.useState("");

  const ResetPassword = async () => {
    if (!password) {
      toast.error("Please enter password");
      return;
    }
    if (!user) {
      toast.error("No user found");
      return;
    }
    try {
      const payload = {
        NewPassword: password,
        username: user.username,
      };
      const response = await UserAPI("resetPassword", [""], { ...payload });
      toast.success("Password reset successfully");
      setPassword("");
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };


  return (
    <div className="w-full mx-auto flex flex-col p-5 border border-gray-300 rounded">
      <h1 className="text-xl text-gray-700 font-bold mb-4 mt-4">
        Account Details
      </h1>
      <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 h-fit">
        <div className="flex flex-col justify-start items-start">
          <div><strong>Email:</strong> {user?.username}</div>
          <div className="mt-5"><strong>Fullname:</strong> {user?.fullname}</div>
          <div className="mt-5"><strong>Phone Number:</strong> {user?.phonenumber}</div>
        </div>
        <div className="flex flex-col justify-start items-start">
          <div><strong>City:</strong> {user?.city}</div>
          <div className="mt-5"><strong>School:</strong> {user?.school}</div>
          <div className="mt-5">
            <strong>Account Created Date:</strong>{" "}
            {user?.accountcreateddate && FormatDate(user?.accountcreateddate)}
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full mb-5 border border-gray-300 rounded overflow-hidden mt-8">
        <input
          type="text"
          placeholder="New Password"
          className="p-1 md:p-2 w-full focus:outline-none"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <button
          className="bg-rose-500 text-white p-1 md:p-2 w-[50%] md:w-[30%] font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={ResetPassword}
          disabled={loading || password === ""}
        >
          Reset Password
        </button>
      </div>
    </div>
  )
}

