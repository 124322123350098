import { backendUrl } from "constants/config";
import { ApiInterface } from "../api-interface/types";
import axios from "axios";

export const coursesPricingApis: ApiInterface[] = [
  {
    url: "/api/course-pricings/add",
    method: "PUT",
    name: "addCoursePricing",
  },
  {
    url: "/api/course-pricings/get",
    method: "GET",
    name: "getCoursePricing",
  },
  {
    url: "/api/course-pricings/get-new",
    method: "GET",
    name: "getNewCoursePricing",
  },
  {
    url: "/api/course-pricings/update",
    method: "PUT",
    name: "updateCoursePricing",
  },
  {
    url: "/api/course-pricings/handle-status",
    method: "PUT",
    name: "handleCoursePricingStatus",
  }
];

export function CoursesPricingAPI(name: string, params: string[] = [""], data: any = null) {
  const api = coursesPricingApis.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");


  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url
  });
}

