import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DisplayCommissionSection = ({ user }) => {
  const navigate = useNavigate();

  const [apiResponse, setApiResponse] = useState({
    isError: false,
    message: "",
    commissionId: null,
    isLoading: false,
  });
  const fetchLoggedinCommissionUserId = async () => {
    setApiResponse({
      isError: false,
      message: "",
      commissionId: null,
      isLoading: true,
    });

    try {
      const response = await CommissionAPI("getLoggedinCommissionUserId");
      setApiResponse({
        isError: false,
        message: "response?.data?.message",
        commissionId: response?.data?.commissionId,
        isLoading: false,
      });
    } catch (error) {
      const response = error?.response;
      if (response?.data?.message) {
        setApiResponse({
          isError: true,
          message: response?.data?.message,
          commissionId: null,
          isLoading: false,
        });
        console.log("Message: ", response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (user?.isloggedin && user?.is_commission_user) {
      fetchLoggedinCommissionUserId();
    }
  }, [user?.isloggedin]);

  if (apiResponse.isLoading) return null;
  if (!apiResponse?.isLoading && apiResponse?.isError) return null;

  if (
    !apiResponse?.isLoading &&
    !apiResponse?.isError &&
    !apiResponse?.commissionId
  )
    return null;

  if (
    !apiResponse?.isLoading &&
    !apiResponse?.isError &&
    apiResponse?.commissionId
  )
    return (
      <div className="bg-[#F7F6F7] flex flex-col justify-start shadow-xl rounded-xl p-4 min-h-32">
        <h1 className="text-2xl text-gray-700 font-bold text-left">
          Commission System
        </h1>
        <button
          className="flex gap-2 bg-primary text-white text-sm font-semibold py-1 px-4 rounded shadow-md hover:bg-white border border-primary hover:border-primary hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed mt-4"
          onClick={() => {
            const url = "/manage/commission/view/" + apiResponse?.commissionId;
            navigate(url);
          }}
        >
          View Dashboard
        </button>
      </div>
    );
};

export default DisplayCommissionSection;
