import React, { useEffect, useRef, useState } from "react";
import { Select, Table, Input, TableProps } from 'antd';
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";

const { Search } = Input;

interface GlobalDragableTableProps {
  data: any[]; 
  columns: TableProps<any>['columns'];
  searchPlaceholder?: string;
  setUpDatedDataSource: React.Dispatch<React.SetStateAction<any[]>>;
}

const Row: React.FC<any> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS?.Translate?.toString(transform),
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const GlobalDragableTable: React.FC<GlobalDragableTableProps> = ({
  data,
  columns,
  searchPlaceholder = "Search",
  setUpDatedDataSource,
}) => {
  const [dataSource, setDataSource] = useState<any[]>(data);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const initialOrder = useRef(data?.map((item) => item.key));
  const pageSize = 200;
useEffect(() => {
  setDataSource(data);
}, [data]);
  const handleSearch = (value: string) => {
    const filtered = data?.filter(record => {
      return Object?.values(record).some(val =>
        val?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      );
    });
    setDataSource(filtered);
    setSearchText(value);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setIsDragging(false);
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
      setUpDatedDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const paginatedData = isDragging
    ? dataSource
    : dataSource?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="min-w-full">
      <Search
        placeholder={searchPlaceholder}
        value={searchText}
        onChange={e => handleSearch(e.target.value)}
        className='w-1/2 my-4 '
      />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={dataSource?.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={paginatedData}
            pagination={
              !isDragging && {
                current: currentPage,
                pageSize,
                total: dataSource?.length,
                onChange: (page) => setCurrentPage(page),
                showSizeChanger: false,
              }
            }
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default GlobalDragableTable;
