import { useEffect, useState } from 'react';
import CustomBadges from '../../../../shared-components/ui/CustomBadges';
import { EditIcon } from '../../../../shared-components/ui/Icons';
import { customAPIHandler } from "(apis)/api-interface/api-handler";
import toast from "react-hot-toast";

export default function NewOrderManager() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("PRE-MEDICAL");
    const [filteredData, setFilteredData] = useState([]);
    const [coursesData, setCoursesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50; // Consistent with your existing pagination logic

    const getCourseData = async () => {
        try {
            setIsLoading(true);
            const res = await customAPIHandler("getCourseOrderDetails", "", null, selectedCategory);
            if (res?.status === 200) {
                const data = res?.data?.data || [];
                setCoursesData(data);
                setFilteredData(data);
                setCurrentPage(1);
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
            setCoursesData([]);
            setFilteredData([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCourseData();
    }, [selectedCategory]); // Add dependency to re-fetch when category changes

    const GetTime = (date: string) => {
        const d = new Date(date);
        return d.toLocaleTimeString();
    }

    const GetDate = (date: string) => {
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    }

    const getPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData.slice(startIndex, endIndex);
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }

    // Calculate total pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <div className='w-[95%] mx-auto h-full'>
            <h1 className='text-3xl text-gray-700 font-bold'>Course Manager</h1>
            <section className="my-5 antialiased">
                <div className="mx-auto">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div className="bg-white shadow-md sm:rounded-lg">
                            <table className="w-full mb-20 text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-4 py-4">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Date of Order
                                        </th>

                                        <th scope="col" className="px-4 py-4">
                                            Time of Order
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-left">
                                            Customer Name
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-left">
                                            Coupon Used
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Subscription Status
                                        </th>

                                        <th scope="col" className="px-4 py-4 text-center">
                                            Course Name
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            SKU
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Total Amount
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Total Discount
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Paid Amount
                                        </th>

                                        <th scope="col" className="px-4 py-3 text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getPageData().map((order, index) => (
                                        <tr className="border-b" key={order._id}>
                                            <td className="px-4 py-3">{index + 1}</td>
                                            <td className="px-4 py-3">{GetDate(order.CreationDate)}</td>
                                            <td className="px-4 py-3">{GetTime(order.CreationDate)}</td>
                                            <td className="px-4 py-3">{order.UserName}</td>
                                            <td className="px-4 py-3">{order.CouponCode}</td>
                                            <td className={`px-4 py-3 text-center`} >
                                                {order.OrderStatus === "Pending" && (<CustomBadges label="Pending" type="warning" />)}
                                                {order.OrderStatus === "Accepted" && (<CustomBadges label="Active" type="success" />)}
                                                {order.OrderStatus === "Denied" && (<CustomBadges label="Expired" type="light" />)}
                                            </td>
                                            <td className="px-4 py-3 text-center">{order?.course_purchases?.[0].course_name}</td>
                                            <td className="px-4 py-3 text-center">{order?.course_purchases?.[0].sku_name}</td>
                                            <td className="px-4 py-3 text-center">{order?.BREAKDOWN?.TotalAmount?.toFixed(0)}</td>
                                            <td className="px-4 py-3 text-center">{order?.BREAKDOWN?.TotalDiscount?.toFixed(0)}</td>
                                            <td className="px-4 py-3 text-center">{order?.BREAKDOWN?.GrandTotal?.toFixed(0)}</td>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        toast.success("Coming Soon");
                                                        //PaymentProof
                                                        window.location.href = `/verifyorder/${selectedCategory}/${order._id}`;
                                                        // window.open(`${order?.PaymentProof}`, "_blank");
                                                        // window.location.href = `/verifyorder/${order._id}`;
                                                    }}
                                                >
                                                    <EditIcon size="w-6 h-6" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='flex flex-row gap-2 justify-center items-center mb-10'>
                                <button
                                    disabled={currentPage === 1}
                                    className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed'
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </button>
                                <div className='flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10'>
                                    {currentPage} / {totalPages}
                                </div>
                                <button
                                    disabled={currentPage === totalPages}
                                    className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed'
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

