import { apiHandler, apiRequest } from '(apis)/api-interface/api-handler';
import React, { useContext, useEffect, useState } from 'react'
import { Select } from 'antd';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { categoryValues } from 'constants/constantvalues';
import { X } from '@mui/icons-material';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from 'state-management/user-context';
import { DeleteIcon, EditIcon, EyeIcon } from 'shared-components/ui/Icons';

export const ExternalPaymentModal = ({ ShowModel, setShowModel, isEditMode, selectedExternalPayment, fetchExternalPayments, setModalType }: { ShowModel: boolean, setShowModel: (value: boolean) => void, isEditMode: boolean, selectedExternalPayment: any, fetchExternalPayments: () => void, setModalType: (value: string) => void }) => {

    const [serviceProviders, setServiceProviders] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const { user } = useContext(UserContext);

    const GetServiceProviders = async () => {
        const res = await apiRequest("getServiceProviders");
        if (res.data?.isSuccess) {
            setServiceProviders(res.data?.serviceProviders);
        }
        const res2 = await apiRequest("getBankAccounts");
        if (res2.data?.isSuccess) {
            setBankAccounts(res2.data?.bankAccounts);
            setDepartments(res2.data?.departments);
        }
    }

    useEffect(() => {
        GetServiceProviders();
    }, [])


    interface BreakDownAmounts {
        category: string;
        amount: number;
        department: string;
    }

    const [formData, setFormData] = useState<{
        serviceProviderId: string;
        breakdownAmounts: BreakDownAmounts[];
        paidByOurAccounts: string[];
        addedBy: string;
        modifiedBy: string;
        screenshot: string;
        createdAt: Date;
        payment_date: Date;
    }>({
        serviceProviderId: "",
        breakdownAmounts: [],
        paidByOurAccounts: [],
        addedBy: "",
        modifiedBy: "",
        screenshot: "",
        createdAt: new Date(),
        payment_date: new Date()
    })

    useEffect(() => {
        if (selectedExternalPayment && isEditMode) {
            setFormData({
                serviceProviderId: selectedExternalPayment?.serviceProvider._id,
                breakdownAmounts: selectedExternalPayment?.breakdownAmounts.map((item: any) => ({
                    category: item.category,
                    amount: item.amount,
                    department: item.department
                })),
                paidByOurAccounts: selectedExternalPayment?.paidByOurAccounts,
                createdAt: new Date(selectedExternalPayment?.createdAt),
                addedBy: selectedExternalPayment?.addedBy,
                modifiedBy: selectedExternalPayment?.modifiedBy,
                screenshot: selectedExternalPayment?.screenshot,
                payment_date: new Date(selectedExternalPayment?.payment_date) || new Date()
            });
        }
    }, [selectedExternalPayment, isEditMode]);


    const handleSubmit = async () => {
        try {
            //validate form data
            if (formData.breakdownAmounts.length === 0) {
                toast.error("Please add at least one breakdown amount");
                return;
            }
            //make sure all breakdown amounts are numbers and not negative
            formData.breakdownAmounts.forEach((item) => {
                if (isNaN(Number(item.amount)) || Number(item.amount) < 0) {
                    toast.error("Please enter valid numbers for breakdown amounts");
                    return;
                }
            });
            //iterate through breakdown amounts and make sure every field is filled
            formData.breakdownAmounts.forEach((item) => {
                if (!item.category || !item.amount || !item.department) {
                    toast.error("Please fill all fields for breakdown amounts");
                    return;
                }
            });
            //make sure serviceProviderId is not empty
            if (!formData.serviceProviderId) {
                toast.error("Please select a service provider");
                return;
            }
            //make sure total amount is not negative
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.amount), 0) < 0) {
                toast.error("Total amount cannot be negative");
                return;
            }
            //make sure total amount is not zero
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.amount), 0) === 0) {
                toast.error("Total amount cannot be zero");
                return;
            }
            //make sure user is logged in
            if (!user?.username) {
                toast.error("Please login to continue");
                return;
            }
            //make sure createdAt is not in the future
            if (formData.createdAt > new Date()) {
                toast.error("Created at cannot be in the future");
                return;
            }
            //make sure paidByOurAccounts is not empty
            if (formData.paidByOurAccounts.length === 0) {
                toast.error("Please select at least one paid by our account");
                return;
            }
            //make sure screenshot is not empty
            if (!formData.screenshot) {
                toast.error("Please upload a screenshot");
                return;
            }


            //make sure payment_date is not in the future
            if (formData.payment_date > new Date()) {
                toast.error("Payment date cannot be in the future");
                return;
            }

            const payload = {
                ...formData,
                totalAmount: formData.breakdownAmounts.reduce((sum, item) =>
                    sum + Number(item.amount), 0),
                addedBy: isEditMode ? selectedExternalPayment?.addedBy : user?.username,
                modifiedBy: isEditMode ? selectedExternalPayment?.modifiedBy : user?.username
            };

            const res = await apiHandler({
                data: payload,
                name: isEditMode ? "updateExternalPayment" : "createExternalPayment",
                params: isEditMode ? [`${selectedExternalPayment?._id}`] : []
            });

            if (res.data?.isSuccess) {
                toast.success("Payment submitted successfully");

                setShowModel(false);
                setModalType("add");
                // Reset form   
                setFormData({
                    serviceProviderId: "",
                    breakdownAmounts: [],
                    paidByOurAccounts: [],
                    addedBy: "",
                    modifiedBy: "",
                    screenshot: "",
                    createdAt: new Date(),
                    payment_date: new Date()
                });
                fetchExternalPayments();
            }
        } catch (error) {
            console.error("Payment submission error:", error);
            toast.error("Payment submission error");
        }
    };

    const convertToBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-4xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {isEditMode ? "Update" : "Add New"} Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setFormData({
                                    serviceProviderId: "",
                                    breakdownAmounts: [],
                                    paidByOurAccounts: [],
                                    addedBy: "",
                                    modifiedBy: "",
                                    createdAt: new Date(),
                                    payment_date: new Date(),
                                    screenshot: ""
                                })
                                setShowModel(false);
                                setModalType("add");
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/*SELECT EMPLOYEE*/}
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between gap-2'>
                            <div className='flex flex-col gap-2 w-full'>
                                <label htmlFor="serviceProviderId">Service Provider</label>
                                <Select
                                    disabled={isEditMode}
                                    showSearch
                                    placeholder="Select a service provider"
                                    options={serviceProviders.map((serviceProvider) => ({ label: serviceProvider.name, value: serviceProvider._id }))}
                                    onChange={(value) => {
                                        setFormData({ ...formData, serviceProviderId: value });
                                    }}
                                    className="w-full h-10 border border-gray-300 font-rubik"
                                    bordered={false}
                                    value={formData.serviceProviderId}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                />
                            </div>
                        </div>


                        {/* Breakdown Amounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payment Breakdown</label>
                            {formData.breakdownAmounts.map((item, index) => (
                                <div key={index} className="grid grid-cols-3 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg">
                                    <CustomDropDown
                                        options={categoryValues.map((category) => category.value)}
                                        onSelect={(value) => {
                                            const newBreakdown = [...formData.breakdownAmounts];
                                            newBreakdown[index].category = value;
                                            setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                        }}
                                        value={item.category}
                                        label="Category"
                                        width="w-full"
                                    />

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="amount">Amount</label>
                                        <input
                                            type="number"
                                            placeholder="Amount"
                                            className="border p-2 rounded w-full bg-transparent h-[52px]"
                                            value={item.amount || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].amount = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <CustomDropDown
                                            options={departments.map((department) => department.name)}
                                            onSelect={(value) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].department = value;
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                            label="Department"
                                            width="w-full"
                                            value={item.department}
                                        />
                                    </div>


                                    <X onClick={() => {
                                        const newBreakdown = formData.breakdownAmounts.filter((_, i) => i !== index);
                                        setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                    }}
                                        className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                                    />
                                </div>
                            ))}
                            <ButtonFill
                                handleClick={() => {
                                    setFormData({
                                        ...formData,
                                        breakdownAmounts: [...formData.breakdownAmounts, {
                                            category: '',
                                            amount: 0,
                                            department: ''
                                        }]
                                    });
                                }}
                            >
                                Add Breakdown
                            </ButtonFill>
                        </div>

                        {/* Paid By Our Accounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Paid By Our Accounts</label>
                            <Select
                                placeholder="Select paid by our accounts"
                                options={bankAccounts.map((bankAccount) => ({
                                    label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                    value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                }))}
                                onChange={(value) => {
                                    setFormData({ ...formData, paidByOurAccounts: value });
                                }}
                                className="w-full h-10 border border-gray-300 font-rubik"
                                bordered={false}
                                value={formData.paidByOurAccounts}
                                mode='multiple'
                            />
                        </div>

                        {/* Added By Section */}

                        {/* Created At Section */}
                        <div className="mt-4">
                            <label className="block mb-2">For Month</label>
                            <input type="date" placeholder="Created At" className="border p-2 rounded" value={formData.createdAt.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, createdAt: new Date(e.target.value) })} />
                        </div>

                        <div className="mt-4">
                            <label className="block mb-2">Payment Date</label>
                            <input type="date" placeholder="Payment Date" className="border p-2 rounded" value={formData.payment_date.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, payment_date: new Date(e.target.value) })} />
                        </div>

                        {/* Screenshot Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Screenshot</label>
                            <input type="file" className="border p-2 rounded"
                                accept="image/png, image/jpeg"
                                onChange={async (e) => {
                                    //upload in base64  
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        const base64 = await convertToBase64(file);
                                        setFormData({ ...formData, screenshot: base64 as string });
                                    }
                                }} />
                            {formData.screenshot && <img src={formData.screenshot} alt="Screenshot" className="w-24 h-24 object-fit border mt-2 rounded" />}
                        </div>

                        {/* Submit Button */}
                        <div className="mt-4 flex justify-end">
                            <ButtonFill
                                handleClick={handleSubmit}
                                disabled={!formData.serviceProviderId}
                            >
                                {isEditMode ? 'Update' : 'Submit'} Payment
                            </ButtonFill>
                        </div>
                        <Toaster />
                    </div>

                </div>
            </div>
        </div>
    )
}


export const ViewExternalPaymentModal = ({ ShowModel, setShowModel, selectedExternalPayment }: { ShowModel: boolean, setShowModel: (value: boolean) => void, selectedExternalPayment: any }) => {

    const GetDate = (date: Date) => {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (
        <div
            id="viewPaymentModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-6xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            View Payment
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* TODO: Add Payment Details */}
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-2'>
                            <b>Service Provider:</b> {selectedExternalPayment?.serviceProvider?.name}
                        </div>
                        <div className='flex flex-row gap-2'>
                            <b>Amount Paid:</b> {selectedExternalPayment?.totalAmount}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <b>Paid By Our Accounts:</b>
                            <div className='flex flex-row gap-2'>
                                {selectedExternalPayment?.paidByOurAccounts.map((account: any, index: number) =>
                                    <div key={index}>
                                        <b>{index + 1}.</b> {account}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <b>Breakdown Amounts:</b>
                            <div className='flex flex-row gap-2 w-full'>
                                <table className='w-full'>
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                No.
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Category
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Amount
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Department
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedExternalPayment?.breakdownAmounts.map((item: any, index: number) =>
                                            <tr key={index} className="border-b">
                                                <td className="px-4 py-2 text-center">{index + 1}</td>
                                                <td className="px-4 py-2 text-center">{item?.category}</td>
                                                <td className="px-4 py-2 text-center">{item?.amount}</td>
                                                <td className="px-4 py-2 text-center">{item?.department}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='flex flex-row gap-2 justify-between'>
                            <div className='flex justify-start gap-2'><b>Added By:</b> {selectedExternalPayment?.addedBy}</div>
                            <div className='flex justify-end gap-2'><b>Created At:</b> {GetDate(selectedExternalPayment?.createdAt)}</div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    )
}

export const ExternalPaymentsTable = (
    {
        externalPayments,
        setOpenAddExternalPaymentModal,
        setModalType,
        setSelectedExternalPayment,
        setOpenViewExternalPaymentModal,
        GetDate,
        fetchExternalPayments
    }: {
        externalPayments: any[],
        setOpenAddExternalPaymentModal: (value: boolean) => void,
        setModalType: (value: string) => void,
        setSelectedExternalPayment: (value: any) => void,
        setOpenViewExternalPaymentModal: (value: boolean) => void,
        GetDate: (date: string) => string,
        fetchExternalPayments: () => void
    }) => {

    const [filteredData, setFilteredData] = useState(externalPayments);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        //name and date 
        const filteredData = externalPayments.filter((payment) => {
            return payment.serviceProvider.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                payment.createdAt.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setFilteredData(filteredData);
        setCurrentPage(1);
    };

    const getPageData = () => {
        const startIndex = (currentPage - 1) * 10;
        return filteredData.slice(startIndex, startIndex + 10);
    };

    const DeleteExternalPayment = async (id: string) => {
        try {
            const response = await apiHandler({
                name: "deleteExternalPayment",
                params: [id]
            })
            if (response.data.isSuccess) {
                toast.success(response.data.message);
                fetchExternalPayments();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            <div className='flex flex-row mb-4'>
                <input type="text" placeholder='Search' value={searchText} onChange={handleSearch} className='border p-2 rounded w-full bg-transparent' />
                <button className='bg-primary text-white p-2 rounded'
                    onClick={() => {
                        setSearchText('');
                        setFilteredData(externalPayments);
                        setCurrentPage(1);
                    }}
                >Reset</button>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-4 py-4">
                                Date of Payment
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Service Provider
                            </th>
                            <th scope="col" className="px-4 py-4 text-left">
                                Amount Paid
                            </th>
                            <th scope="col" className="px-4 py-4 text-left">
                                Screenshot
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getPageData().map((payment) => (
                            <tr className="border-b" key={payment?._id}>
                                <td className="px-4 py-3">{GetDate(payment?.createdAt)}</td>
                                <td className="px-4 py-3">{payment?.serviceProvider?.name}</td>
                                <td className="px-4 py-3">{payment?.totalAmount}</td>
                                <td className="px-4 py-3">
                                    <div className='text-blue-500 cursor-pointer underline'
                                        onClick={() => {
                                            window.open(payment?.screenshot, '_blank');
                                        }}
                                    >
                                        Screenshot Link
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-center flex items-center justify-center">
                                    <div className="cursor-pointer flex items-center gap-4">
                                        <div className='cursor-pointer'
                                            onClick={() => {
                                                setOpenAddExternalPaymentModal(true);
                                                setModalType("edit");
                                                setSelectedExternalPayment(payment);
                                            }}
                                        >
                                            <EditIcon
                                                size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                            />
                                        </div>
                                        <div className='cursor-pointer'
                                            onClick={() => {
                                                setOpenViewExternalPaymentModal(true);
                                                setSelectedExternalPayment(payment);
                                            }}
                                        >

                                            <EyeIcon
                                                size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                            />
                                        </div>
                                        <div className='cursor-pointer'
                                            onClick={() => {
                                                DeleteExternalPayment(payment?._id);
                                            }}
                                        >
                                            <DeleteIcon
                                                size='w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300'
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='flex flex-row gap-2 justify-center items-center'>
                <button
                    disabled={currentPage === 1}
                    className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed'
                    onClick={() => handlePageChange(currentPage - 1)}
                >Previous</button>
                <div className='flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10'>
                    {currentPage}
                </div>
                <button
                    disabled={currentPage === Math.ceil(filteredData.length / 10)}
                    className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed'
                    onClick={() => handlePageChange(currentPage + 1)}
                >Next</button>
            </div>
        </>
    )
}
