import axios from 'axios';
import React from 'react'

const CDN_BASE_URL = 'https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Finance';
const EXPENSE_CATEGORIES = {
    ACADEMICS: 'Academics',
    MARKETING: 'Marketing',
    DESIGN: 'Design',
    AHS_ACADEMICS: 'AHS-Academics',
    SALES: 'Sales',
    MISC: 'Misc',
    SALARIES: 'Salaries',
} as const;

interface ExpenseData {
    _id: string;
    Payment: number;
    "Payment Date": string;
    "DATE": string;
    "Payment_Date": string;
    Name_Of_Payee: string;
    Project_Name: string;
    NAME: string;
    DEPARTMENTS: string;
}

export default function Page() {

    const [expensesData, setExpensesData] = React.useState<any[]>([]);
    // Fetch expense data for a specific category
    const fetchExpenseData = async (category: string) => {
        try {
            const response = await axios.get(`${CDN_BASE_URL}/${category}.json`);
            setExpensesData((prevData: ExpenseData[]) => [...prevData, { category, data: response.data }]);
        } catch (error) {
            console.error(`Error fetching ${category} data:`, error);
            return { data: [], total: 0 };
        }
    };

    React.useEffect(() => {
        Object.values(EXPENSE_CATEGORIES).forEach(category => fetchExpenseData(category));
    }, []);

    const [selectedCategory, setSelectedCategory] = React.useState<string | null>("Academics");


    return (
        <div className='w-[95%] mx-auto'>
            <div className='p-4flex flex-col gap-2'>
                <h2 className='font-bold text-3xl text-gray-800 mb-4 mt-4'>Previous Revenue Data</h2>
                <div className='flex flex-wrap gap-2 mb-4 mt-4 justify-center items-center font-bold'>
                    {Object.values(EXPENSE_CATEGORIES).map((category) => (
                        <button key={category} onClick={() => setSelectedCategory(category)} className={`shadow-2xl p-2 rounded-md border-2 border-gray-200 ${selectedCategory === category ? 'bg-gray-200' : ''}`}>{category}</button>
                    ))}
                </div>

                {selectedCategory === "Academics" &&
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        Payment Date
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Name Of Payee
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Payment Amount
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Project Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesData.find(item => item.category === selectedCategory)?.data.map((academic: ExpenseData) => (
                                    <tr className="border-b" key={academic._id}>
                                        <td className="px-4 py-3">
                                            {academic.Payment_Date}
                                        </td>
                                        <td className="px-4 py-3">
                                            {academic.Name_Of_Payee.charAt(0).toUpperCase() + academic.Name_Of_Payee.slice(1)}
                                        </td>
                                        <td className="px-4 py-3">
                                            {academic.Payment}
                                        </td>
                                        <td className="px-4 py-3">
                                            {academic.Project_Name.charAt(0).toUpperCase() + academic.Project_Name.slice(1)}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                }

                {(selectedCategory === "Marketing" || selectedCategory === "Design" || selectedCategory === "AHS-Academics" || selectedCategory === "Sales" || selectedCategory === "Misc") &&
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        Payment Date
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Name Of Payee
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Payment Amount
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Project Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesData.find(item => item.category === selectedCategory)?.data.map((marketing: ExpenseData) => (
                                    <tr className="border-b" key={marketing._id}>
                                        <td className="px-4 py-3">{marketing["Payment Date"]}</td>
                                        <td className="px-4 py-3">{marketing["Name Of Payee"]?.charAt(0)?.toUpperCase() + marketing["Name Of Payee"]?.slice(1)}</td>
                                        <td className="px-4 py-3">{marketing["Payment"]}</td>
                                        <td className="px-4 py-3">{marketing["Project Name"]?.charAt(0)?.toUpperCase() + marketing["Project Name"]?.slice(1)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                }

                {selectedCategory === "Salaries" &&

                    <div className='overflow-x-auto'>
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        Payment Date
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Name Of Payee
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Payment Amount
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-left">
                                        Project Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesData.find(item => item.category === selectedCategory)?.data.map((salary: ExpenseData) => (
                                    <tr className="border-b" key={salary._id}>
                                        <td className="px-4 py-3">{salary["DATE"]}</td>
                                        <td className="px-4 py-3">{salary["NAME"]?.charAt(0)?.toUpperCase() + salary["NAME"]?.slice(1)}</td>
                                        <td className="px-4 py-3">{salary["Payment"]}</td>
                                        <td className="px-4 py-3">{salary["DEPARTMENTS"]?.charAt(0)?.toUpperCase() + salary["DEPARTMENTS"]?.slice(1)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }

            </div>
        </div>
    )
}
