import React from 'react';
import axios from 'axios';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,
    Tooltip, Legend, BarChart, Bar, ResponsiveContainer,
    PieChart, Pie, Cell
} from 'recharts';
import { backendUrl, GetBackendUrlForCategory } from 'constants/config';
import { formatNumberWithCommas } from 'shared-pages/latest-commission/utils';
import Loader from 'shared-components/Loader';
import Loading from 'shared-components/ui/Loading';

// Types
interface OrderData {
    Orders: any[];
    CourseOrders: any[];
    TotalRevenue: number;
    TotalCouponDiscount: number;
    TotalDiscount: number;
    TotalOrders: number;
    TotalCourseRevenue: number;
    TotalCourseCouponDiscount: number;
    TotalCourseDiscount: number;
    TotalCourseOrders: number;
    OldOrders: any[];
}

interface ExpenseData {
    Payment: number;
}

// Constants
const CDN_BASE_URL = 'https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Finance';
const EXPENSE_CATEGORIES = {
    ACADEMICS: 'Academics',
    MARKETING: 'Marketing',
    DESIGN: 'Design',
    AHS_ACADEMICS: 'AHS-Academics',
    SALES: 'Sales',
    MISC: 'Misc',
    SALARIES: 'Salaries',
} as const;

// Add interface for monthly revenue data
interface MonthlyRevenue {
    'month/year': string;
    totalRevenue: number;
    totalOrders: number;
    Commissions: number;
    InternalExpense: number;
    ExternalExpense: number;
    MiscellaneousExpense: number;
}

const OrderStatistics: React.FC = () => {
    // Combined state for all expenses
    const [expenses, setExpenses] = React.useState<Record<string, { data: ExpenseData[], total: number }>>({});
    const [orders, setOrderData] = React.useState<OrderData | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [ExpensesData, setExpensesData] = React.useState<any[]>([])
    const [NewExpensesData, setNewExpensesData] = React.useState<any[]>([])
    const [commissons, setCommissions] = React.useState<{
        oldCommissionSystem: any,
        newCommissionSystem: any
    }>({
        oldCommissionSystem: null,
        newCommissionSystem: null
    })
    const [newCommissions, setNewCommissions] = React.useState<any>(null)


    // Fetch expense data for a specific category
    const fetchExpenseData = async (category: string) => {
        try {
            const response = await axios.get(`${CDN_BASE_URL}/${category}.json`);
            const total = response.data.reduce((sum: number, item: ExpenseData) => sum + item.Payment, 0);

            let monthlyExpense = [];
            response.data.forEach((item: ExpenseData) => {
                // Extract and format date to YYYY-MM format for monthly grouping
                let date = "";
                if (category.toLowerCase() === "academics") {
                    date = item["Payment_Date"] ? item["Payment_Date"].substring(0, 7) : "";
                } else if (category.toLowerCase() === "salaries") {
                    //"2023-07-01 //YYYY-MM-DD", CONVERT TO YYYY-MM
                    date = item["DATE"] ? item["DATE"].substring(0, 7) : "";
                } else {
                    date = item["Payment Date"] ? item["Payment Date"].substring(0, 7) : "";
                }

                // Find or create monthly entry
                let existingData = monthlyExpense.find(entry => entry["month/year"] === date);
                if (existingData) {
                    existingData.expense += category.toLowerCase() === "salaries" ? item["Payment"] || 0 : item.Payment || 0;
                } else {
                    monthlyExpense.push({
                        "month/year": date,
                        "expense": category.toLowerCase() === "salaries" ? item["Payment"] || 0 : item.Payment || 0
                    });
                }
            });

            const updatedExpensesData = ExpensesData;

            const Item = {
                Category: category.toLowerCase() !== "ahs-academics" ? "PRE-MEDICAL" : "ALLIED HEALTH SCIENCES",
                Department: category,
                TotalExpense: total,
                MonthlyExpense: monthlyExpense
            };


            // Create a new variable to store the updated expenses data
            const existingIndex = updatedExpensesData.findIndex(
                item => item.Category === Item.Category && item.Department === Item.Department
            );

            if (existingIndex >= 0) {
                updatedExpensesData[existingIndex] = Item;
            } else {
                updatedExpensesData.push(Item);
            }
            setExpensesData(updatedExpensesData);

            return { data: response.data, total };
        } catch (error) {
            console.error(`Error fetching ${category} data:`, error);
            return { data: [], total: 0 };
        }
    };
    //    console.log(ExpensesData, "expenses");
    const fetchOrderData = async () => {
        try {
            const backendUrl = `${GetBackendUrlForCategory('PRE-MEDICAL')}/api/revenue/get-revenue-data`;
            const oldOrdersUrl = `${CDN_BASE_URL}/old-orders.json`;

            const [response, oldDataResponse] = await Promise.all([
                axios.get(backendUrl),
                axios.get(oldOrdersUrl)
            ]);

            const { Orders: currentOrders, ...summaryData } = response.data.data;
            const oldOrders = oldDataResponse.data;

            // Aggregate old order data
            const structuredData = [...currentOrders];
            oldOrders.forEach((item) => {
                const date = item["CreationDate/$date"].split('T')[0];
                const existingData = structuredData.find(entry => entry.date === date);

                if (existingData) {
                    existingData.totalRevenue += item.PaidAmount || 0;
                    existingData.totalCouponDiscount += item.DiscountAmount || 0;
                    existingData.totalDiscount += (item.TotalAmount - item.PaidAmount) || 0;
                    existingData.totalOrders += 1;
                } else {
                    structuredData.push({
                        date,
                        totalRevenue: item.PaidAmount || 0,
                        totalCouponDiscount: item.DiscountAmount || 0,
                        totalDiscount: (item.TotalAmount - item.PaidAmount) || 0,
                        totalOrders: 1
                    });
                }
            });

            // Calculate aggregate values for old orders
            const oldOrderSummary = oldOrders.reduce((acc, item) => {
                acc.totalRevenue += item.PaidAmount || 0;
                acc.totalCouponDiscount += item.DiscountAmount || 0;
                acc.totalDiscount += (item.TotalAmount - item.PaidAmount) || 0;
                acc.totalOrders += 1;
                return acc;
            }, {
                totalRevenue: 0,
                totalCouponDiscount: 0,
                totalDiscount: 0,
                totalOrders: 0
            });

            // Update state with aggregated data
            setOrderData({
                Orders: structuredData,
                CourseOrders: summaryData.CourseOrders,
                TotalRevenue: summaryData.TotalRevenue + oldOrderSummary.totalRevenue,
                TotalCouponDiscount: summaryData.TotalCouponDiscount + oldOrderSummary.totalCouponDiscount,
                TotalDiscount: summaryData.TotalDiscount + oldOrderSummary.totalDiscount,
                TotalOrders: summaryData.TotalOrders + oldOrderSummary.totalOrders,
                TotalCourseRevenue: summaryData.TotalCourseRevenue,
                TotalCourseCouponDiscount: summaryData.TotalCourseCouponDiscount,
                TotalCourseDiscount: summaryData.TotalCourseDiscount,
                TotalCourseOrders: summaryData.TotalCourseOrders,
                OldOrders: oldOrders
            });
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    };

    const [revenueData, setRevenueData] = React.useState<any>(null);

    const fetchRevenueData = async () => {
        try {
            const response = await axios.get(`${backendUrl}/api/finance/get-revenue-summary`);
            if (response.data.isSuccess) {
                setRevenueData(response.data.data)

                let expenses = []
                response.data.data.internal.forEach((item: any) => {
                    //"2024-11-02", change to YYYY-MM
                    let date = item.date.substring(0, 7);
                    let expense = item.expense;

                    let existingData = expenses.find(entry => entry["month/year"] === date);
                    if (existingData) {
                        existingData.expense += expense;
                    } else {
                        expenses.push({
                            "month/year": date,
                            "expense": expense,
                        });
                    }
                });

                response.data.data.external.forEach((item: any) => {
                    let date = item.date.substring(0, 7);
                    let expense = item.expense;
                    let existingData = expenses.find(entry => entry["month/year"] === date);
                    if (existingData) {
                        existingData.expense += expense;
                    } else {
                        expenses.push({ "month/year": date, "expense": expense });
                    }
                });

                response.data.data.miscellaneous.forEach((item: any) => {
                    let date = item.date.substring(0, 7);
                    let expense = item.expense;
                    let existingData = expenses.find(entry => entry["month/year"] === date);
                    if (existingData) {
                        existingData.expense += expense;
                    } else {
                        expenses.push({ "month/year": date, "expense": expense });
                    }
                });

                setNewExpensesData(expenses);

            }

        } catch (error) {
            console.error('Error fetching revenue data:', error);
        }
    }
    const fetchCommissions = async () => {
        try {
            const response = await axios.get(`${GetBackendUrlForCategory('PRE-MEDICAL')}/api/v2/latest-commission/stats/commission/new/reimbursement`);
            if (response.data.success) {
                setCommissions(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching commissions data:', error);
        }
    }
    const fetchReimbursementStates = async () => {
        try {
            const response = await axios.get(`${GetBackendUrlForCategory('PRE-MEDICAL')}/api/v2/latest-commission/stats/reimbursement-states`);
            if (response.data.success) {
                setNewCommissions(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching reimbursement states data:', error);
        }
    }

    // Fetch all data on component mount
    React.useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            await fetchOrderData();

            const expensePromises = Object.values(EXPENSE_CATEGORIES).map(async (category) => {
                const data = await fetchExpenseData(category);
                return [category, data];
            });

            const expenseResults = await Promise.all(expensePromises);
            const expenseData = Object.fromEntries(expenseResults);
            setExpenses(expenseData);
            setIsLoading(false);
        };

        fetchAllData();
        fetchRevenueData();
        fetchCommissions();
        fetchReimbursementStates();
    }, []);

    if (isLoading || !orders) {
        return <Loading />
    }

    // Render statistics card
    const StatCard = ({ title, value }: { title: string; value: number }) => (
        <div className='p-4 rounded-lg shadow-2xl flex flex-col gap-2'>
            <h2 className='font-bold text-lg text-gray-500'>{title}</h2>
            <p className='text-3xl font-bold text-gray-700'>
                {formatNumberWithCommas(value?.toFixed())}
            </p>
        </div>
    );

    const CalculatePnLPercentage = () => {
        const totalRevenue = orders.TotalRevenue + orders.TotalCourseRevenue;

        const totalExpense = revenueData?.totalInternal + revenueData?.totalExternal + revenueData?.totalMiscellaneous +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MISC)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALES)?.TotalExpense +
            ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALARIES)?.TotalExpense

        const Result = (totalRevenue - totalExpense) / totalRevenue * 100
        return Result
    }


    const GenerateMonthWiseDataForRevenue = (): MonthlyRevenue[] => {
        if (orders.Orders.length === 0) {
            return []
        }
        let monthlyRevenue: MonthlyRevenue[] = [];
        orders.Orders.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);

            if (existingData) {
                existingData.totalRevenue += Number(item.totalRevenue) || 0;
                existingData.totalOrders += Number(item.totalOrders) || 0;
            } else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: Number(item.totalRevenue) || 0,
                    totalOrders: Number(item.totalOrders) || 0,
                    Commissions: 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                });
            }
        });
        orders.CourseOrders.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.totalRevenue += Number(item.totalRevenue) || 0;
                existingData.totalOrders += Number(item.totalOrders) || 0;
            } else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: Number(item.totalRevenue) || 0,
                    totalOrders: Number(item.totalOrders) || 0,
                    Commissions: 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                });
            }
        });
        orders.OldOrders.forEach((item: any) => {
            const date = item["CreationDate/$date"].split('T')[0].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.totalRevenue += Number(item.PaidAmount) || 0;
                existingData.totalOrders += 1;
            } else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: Number(item.PaidAmount) || 0,
                    totalOrders: 1,
                    Commissions: 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                });
            }
        });

        commissons.newCommissionSystem.dailyStats.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.Commissions += Number(item.totalApprovedAmount) || 0;
            } else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    Commissions: Number(item.totalApprovedAmount) || 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                });
            }
        })
        commissons.oldCommissionSystem.dailyStats.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.Commissions += Number(item.totalAmount) || 0;
            } else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    Commissions: Number(item.totalAmount) || 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                });
            }
        })

        revenueData?.internal.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.InternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    InternalExpense: Number(item.expense) || 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        revenueData?.external.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.ExternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    ExternalExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        revenueData?.miscellaneous.forEach((item: any) => {
            const date = item.date.substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.MiscellaneousExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    MiscellaneousExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    Commissions: 0,
                });
            }
        })



        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.InternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    InternalExpense: Number(item.expense) || 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.ExternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    ExternalExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.ExternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    ExternalExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.InternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    InternalExpense: Number(item.expense) || 0,
                    ExternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALES)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.ExternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    ExternalExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })
        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALARIES)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.ExternalExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    ExternalExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    MiscellaneousExpense: 0,
                    Commissions: 0,
                });
            }
        })

        ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MISC)?.MonthlyExpense.forEach((item: any) => {
            const date = item["month/year"].substring(0, 7);
            const existingData = monthlyRevenue.find(entry => entry["month/year"] === date);
            if (existingData) {
                existingData.MiscellaneousExpense += Number(item.expense) || 0;
            }
            else {
                monthlyRevenue.push({
                    "month/year": date,
                    totalRevenue: 0,
                    totalOrders: 0,
                    MiscellaneousExpense: Number(item.expense) || 0,
                    InternalExpense: 0,
                    ExternalExpense: 0,
                    Commissions: 0,
                });
            }
        })
        return monthlyRevenue.sort((a, b) => a["month/year"].localeCompare(b["month/year"]));
    };

    // Add new helper function for department data
    const getDepartmentData = () => {
        const departments = [
            { name: 'Marketing', value: revenueData?.internal.reduce((acc, item) => (item.department === "Marketing") ? acc + item.expense : acc, 0) + revenueData?.external.reduce((acc, item) => (item.department === "Marketing") ? acc + item.expense : acc, 0) + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.TotalExpense },
            { name: 'Design', value: revenueData?.internal.reduce((acc, item) => (item.department === "Design") ? acc + item.expense : acc, 0) + revenueData?.external.reduce((acc, item) => (item.department === "Design") ? acc + item.expense : acc, 0) + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.TotalExpense },
            { name: 'Academics', value: revenueData?.internal.reduce((acc, item) => (item.department === "Academics") ? acc + item.expense : acc, 0) + revenueData?.external.reduce((acc, item) => (item.department === "Academics") ? acc + item.expense : acc, 0) + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.TotalExpense + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.TotalExpense },
            // Add other departments...
        ].filter(dept => dept.value > 0); // Remove departments with no expenses

        return departments;
    };

    const FormatDate = (date: string) => {
        // 2024-01-01 into January 2024
        const month = date.split('-')[1];
        const year = date.split('-')[0];
        const monthName = new Date(month + ' 1, ' + year).toLocaleString('default', { month: 'long' });
        return `${monthName}-${year}`;
    }





    return (
        <div className='w-[95%] mx-auto'>
            <div className='p-4flex flex-col gap-2'>
                <h2 className='font-bold text-3xl text-gray-800 mb-4 mt-4'>Order Statistics</h2>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <StatCard title="Total Earnings" value={
                        orders.TotalRevenue + orders.TotalCourseRevenue
                    } />
                    <StatCard title="Total Expense" value={
                        revenueData?.totalInternal
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALES)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALARIES)?.TotalExpense
                        + revenueData?.totalExternal
                        + revenueData?.totalMiscellaneous
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MISC)?.TotalExpense
                        + (commissons && commissons.oldCommissionSystem && commissons.oldCommissionSystem.overallStats ? commissons.oldCommissionSystem.overallStats.totalAmount : 0) +
                        + (newCommissions && newCommissions.totalCommissionAmount ? newCommissions.totalCommissionAmount : 0)
                    } />
                    <div className={`bg-${CalculatePnLPercentage() > 0 ? 'green-200' : 'red-200'} p-4 rounded-lg shadow-md flex flex-col gap-2`}>
                        <h2 className='font-bold text-lg text-gray-600'>P&L</h2>
                        <p className='text-2xl font-bold text-gray-800'>
                            {CalculatePnLPercentage().toFixed(2)}%
                        </p>
                    </div>


                </div>


                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <StatCard title="Total Orders" value={orders.TotalOrders} />
                    <StatCard title="Total Course Orders" value={orders.TotalCourseOrders} />
                    <StatCard title="Total Orders Revenue" value={orders.TotalRevenue} />
                    <StatCard title="Total Course Revenue" value={orders.TotalCourseRevenue} />


                    {/* <StatCard title="Total Orders Discount" value={orders.TotalDiscount} />
                    <StatCard title="Total Course Discount" value={orders.TotalCourseDiscount} />
                    <StatCard title="Total Orders Coupon Discount" value={orders.TotalCouponDiscount} />
                    <StatCard title="Total Course Coupon Discount" value={orders.TotalCourseCouponDiscount} />
                    */}
                    {/* {Object.entries(expenses).map(([category, { total }]) => (
                        <StatCard
                            key={category}
                            title={`Total ${category} Expense Not Registered`}
                            value={total}
                        />
                    ))} */}
                </div>
            </div>

            <div className='border-2 border-dashed border-gray-300 rounded-lg mt-4 mb-4'></div>

            <div className='flex flex-col gap-2 mt-4'>
                <h2 className='font-bold text-3xl text-gray-800 mb-4 mt-4'>Expense Statistics</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <StatCard title="Total Internal Expense" value={revenueData?.totalInternal
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALES)?.TotalExpense
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALARIES)?.TotalExpense
                    } />
                    <StatCard title="Total External Expense" value={revenueData?.totalExternal} />
                    <StatCard title="Total Miscellaneous Expense" value={revenueData?.totalMiscellaneous
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MISC)?.TotalExpense
                    } />
                    <StatCard title="Total Commissions Paid" value={
                        (commissons && commissons.oldCommissionSystem && commissons.oldCommissionSystem.overallStats ? commissons.oldCommissionSystem.overallStats.totalAmount : 0) +
                        (newCommissions && newCommissions.totalPaidReimbursement ? newCommissions.totalPaidReimbursement : 0)
                    } />
                    <StatCard title="Total Commissions Expense" value={
                        (commissons && commissons.oldCommissionSystem && commissons.oldCommissionSystem.overallStats ? commissons.oldCommissionSystem.overallStats.totalAmount : 0) +
                        (newCommissions && newCommissions.totalCommissionAmount ? newCommissions.totalCommissionAmount : 0)
                    } />
                    <StatCard title="Total Commissions Pending" value={newCommissions && newCommissions.totalPendingCommissionAmount ? newCommissions.totalPendingCommissionAmount : 0} />
                </div>
            </div>

            <div className='border-2 border-dashed border-gray-300 rounded-lg mt-4 mb-4'></div>

            <div className='flex flex-col gap-2 mt-4'>
                <h2 className='font-bold text-3xl text-gray-800 mb-4 mt-4'>Expense By Departments</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <StatCard title="Marketing" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Marketing") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Marketing") ? acc + item.expense : acc, 0)
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.MARKETING)?.TotalExpense
                    } />
                    <StatCard title="Design" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Design") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Design") ? acc + item.expense : acc, 0)
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.DESIGN)?.TotalExpense
                    } />
                    <StatCard title="Academics" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Academics") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Academics") ? acc + item.expense : acc, 0)
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.ACADEMICS)?.TotalExpense +
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.AHS_ACADEMICS)?.TotalExpense
                    } />
                    <StatCard title="Technology" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Technology") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Technology") ? acc + item.expense : acc, 0)
                    } />
                    <StatCard title="Sales" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Sales") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Sales") ? acc + item.expense : acc, 0)
                        + ExpensesData.find(item => item.Department === EXPENSE_CATEGORIES.SALES)?.TotalExpense
                    } />
                    <StatCard title="Customer Relations" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Customer Relations") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Customer Relations") ? acc + item.expense : acc, 0)
                    } />
                    <StatCard title="Human Resources" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Human Resources") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Human Resources") ? acc + item.expense : acc, 0)
                    } />
                    <StatCard title="Finance" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Finance") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Finance") ? acc + item.expense : acc, 0)
                    } />
                    <StatCard title="Product Design" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Product Design") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Product Design") ? acc + item.expense : acc, 0)
                    } />
                    <StatCard title="Organic Marketing" value={
                        revenueData?.internal.reduce((acc, item) => (item.department === "Organic Marketing") ? acc + item.expense : acc, 0)
                        + revenueData?.external.reduce((acc, item) => (item.department === "Organic Marketing") ? acc + item.expense : acc, 0)
                    } />
                </div>





            </div>

            <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2 mt-4'>
                <h2 className='font-bold text-xl text-gray-800'>Monthly Revenue Analysis</h2>

                {/* Bar Chart */}
                <div className="mb-8">
                    <h3 className='font-semibold text-lg text-gray-700'>Monthly Breakdown</h3>
                    <p className='text-gray-600 mb-4'>Detailed view of revenue and expenses per month</p>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={GenerateMonthWiseDataForRevenue()}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis
                                dataKey="month/year"
                                tick={{ fill: '#333' }}
                                axisLine={{ stroke: '#333' }}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis
                                tick={{ fill: '#333' }}
                                axisLine={{ stroke: '#333' }}
                                domain={[0, 'auto']}
                            />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <div className="bg-white p-3 border border-gray-200 rounded shadow-sm">
                                                <p className="font-bold mb-2">{label}</p>
                                                {payload.map((entry, index) => (
                                                    <p key={index} style={{ color: entry.color }} className="mb-1">
                                                        {entry.name}: Rs. {formatNumberWithCommas(entry.value?.toString() || '0')}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Legend />
                            <Bar
                                dataKey="totalRevenue"
                                name="Revenue"
                                fill="#f97316"
                                radius={[4, 4, 0, 0]}
                            />
                            <Bar
                                dataKey="Commissions"
                                name="Commissions"
                                fill="#ef4444"
                                radius={[4, 4, 0, 0]}
                            />
                            <Bar
                                dataKey="InternalExpense"
                                name="Internal Expense"
                                fill="#3b82f6"
                                radius={[4, 4, 0, 0]}
                            />
                            <Bar
                                dataKey="ExternalExpense"
                                name="External Expense"
                                fill="#22c55e"
                                radius={[4, 4, 0, 0]}
                            />
                            <Bar
                                dataKey="MiscellaneousExpense"
                                name="Miscellaneous Expense"
                                fill="#a855f7"
                                radius={[4, 4, 0, 0]}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* New Table View */}
                <div className="mb-8 overflow-x-auto">
                    <h3 className='font-semibold text-lg text-gray-700 mb-4'>Monthly Breakdown Table</h3>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Month/Year
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Revenue
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Commissions
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Internal Expense
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    External Expense
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Misc Expense
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Net Income
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {GenerateMonthWiseDataForRevenue().map((item, index) => {
                                const netIncome = item.totalRevenue -
                                    (item.Commissions + item.InternalExpense +
                                        item.ExternalExpense + item.MiscellaneousExpense);
                                const isPositive = netIncome > 0;

                                return (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {FormatDate(item["month/year"])}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className="text-orange-500 font-medium">
                                                Rs. {formatNumberWithCommas(item.totalRevenue.toFixed())}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className="text-red-500 font-medium">
                                                Rs. {formatNumberWithCommas(item.Commissions.toFixed())}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className="text-blue-500 font-medium">
                                                Rs. {formatNumberWithCommas(item.InternalExpense.toFixed())}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className="text-green-500 font-medium">
                                                Rs. {formatNumberWithCommas(item.ExternalExpense.toFixed())}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className="text-purple-500 font-medium">
                                                Rs. {formatNumberWithCommas(item.MiscellaneousExpense.toFixed())}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`font-medium ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
                                                Rs. {formatNumberWithCommas(netIncome.toFixed())}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Line Chart */}
                <div>
                    <h3 className='font-semibold text-lg text-gray-700'>Trend Analysis</h3>
                    <p className='text-gray-600 mb-4'>Monthly trends of revenue vs. total expenses</p>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={GenerateMonthWiseDataForRevenue().map(item => ({
                                ...item,
                                totalExpenses: item.Commissions + item.InternalExpense + item.ExternalExpense + item.MiscellaneousExpense,
                                netIncome: item.totalRevenue - (item.Commissions + item.InternalExpense + item.ExternalExpense + item.MiscellaneousExpense)
                            }))}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis
                                dataKey="month/year"
                                tick={{ fill: '#333' }}
                                axisLine={{ stroke: '#333' }}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis
                                tick={{ fill: '#333' }}
                                axisLine={{ stroke: '#333' }}
                            />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <div className="bg-white p-3 border border-gray-200 rounded shadow-sm">
                                                <p className="font-bold mb-2">{label}</p>
                                                {payload.map((entry, index) => (
                                                    <p key={index} style={{ color: entry.color }} className="mb-1">
                                                        {entry.name}: Rs. {formatNumberWithCommas(entry.value?.toString() || '0')}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="totalRevenue"
                                name="Revenue"
                                stroke="#f97316"
                                strokeWidth={2}
                                dot={{ r: 4 }}
                            />
                            <Line
                                type="monotone"
                                dataKey="totalExpenses"
                                name="Total Expenses"
                                stroke="#ef4444"
                                strokeWidth={2}
                                dot={{ r: 4 }}
                            />
                            <Line
                                type="monotone"
                                dataKey="netIncome"
                                name="Net Income"
                                stroke="#22c55e"
                                strokeWidth={2}
                                dot={{ r: 4 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2 mt-4'>
                <h2 className='font-bold text-xl text-gray-800'>Department Expense Distribution</h2>

                {/* Pie Chart */}
                <div className="mb-8">
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={getDepartmentData()}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={150}
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {getDepartmentData().map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={[
                                            '#f97316', '#ef4444', '#3b82f6',
                                            '#22c55e', '#a855f7', '#eab308',
                                            '#ec4899', '#14b8a6'
                                        ][index % 8]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip
                                formatter={(value) => `Rs. ${formatNumberWithCommas(value.toString())}`}
                            />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </div>

                {/* Waterfall Chart (using modified bar chart) */}
                <div className="mb-8">
                    <h3 className='font-semibold text-lg text-gray-700'>Revenue Waterfall</h3>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={GenerateMonthWiseDataForRevenue().map(item => ({
                                month: item["month/year"],
                                revenue: item.totalRevenue,
                                expenses: -(item.Commissions + item.InternalExpense + item.ExternalExpense + item.MiscellaneousExpense),
                                net: item.totalRevenue - (item.Commissions + item.InternalExpense + item.ExternalExpense + item.MiscellaneousExpense)
                            }))}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="month"
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis />
                            <Tooltip
                                formatter={(value) => `Rs. ${formatNumberWithCommas(value.toString())}`}
                            />
                            <Legend />
                            <Bar dataKey="revenue" fill="#22c55e" name="Revenue" stackId="stack" />
                            <Bar dataKey="expenses" fill="#ef4444" name="Expenses" stackId="stack" />
                            <Bar dataKey="net" fill="#3b82f6" name="Net" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* Heatmap (Monthly Performance) */}
                <div className="mb-8">
                    <h3 className='font-semibold text-lg text-gray-700'>Monthly Performance Heatmap</h3>
                    <div className="grid grid-cols-4 md:grid-cols-12 gap-1">
                        {GenerateMonthWiseDataForRevenue().map((item, index) => {
                            const profit = item.totalRevenue - (item.Commissions + item.InternalExpense + item.ExternalExpense + item.MiscellaneousExpense);
                            const profitMargin = (profit / item.totalRevenue) * 100;
                            const getColor = (margin: number) => {
                                if (margin > 30) return 'bg-green-500';
                                if (margin > 15) return 'bg-green-300';
                                if (margin > 0) return 'bg-green-100';
                                if (margin > -15) return 'bg-red-100';
                                if (margin > -30) return 'bg-red-300';
                                return 'bg-red-500';
                            };

                            return (
                                <div
                                    key={index}
                                    className={`p-4 ${getColor(profitMargin)} rounded`}
                                    title={`${item["month/year"]}: ${profitMargin.toFixed(1)}% margin`}
                                >
                                    <div className="text-xs font-medium text-center">
                                        {item["month/year"]}
                                    </div>
                                    <div className="text-xs text-center">
                                        {profitMargin.toFixed(1)}%
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderStatistics;