import axios from "axios";
import { toast } from "react-hot-toast";

export const getErrorString = (error: any): string => {
  console.log("error", error);
  if (typeof error === "string") {
    return error;
  }
  if (Array.isArray(error)) {
    return error.map(getErrorString).join(", ");
  }
  if (error && typeof error === "object") {
    return Object.values(error).map(getErrorString).join(", ");
  }
  return "";
};

export const getBase64ImageData = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



export const axiosErrorHandler = (error: any, elseError: string) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const { status, data } = error.response;
      // Prioritize backend message, then fallback to status or generic error
      const errorMessage =
        data?.message || data?.error || elseError || `Error ${status}`;

      toast.error(errorMessage);
    } else if (error.request) {
      // Request was made but no response received
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      toast.error(elseError);
    }
  }
};
