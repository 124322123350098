import { Select, SelectProps } from "antd";
import React from "react";
import CustomFormikError from "./CustomFormikError";
import LabelComponent from "./LabelComponent";
import { FormikProps} from "formik";
import { getErrorString } from "../../utility/form/functions";

interface OptionType {
  label: string;
  value: string | number;
}

interface CustomSelectProps {
  formik: FormikProps<any>;
  options: OptionType[];
  disabled?: boolean;
  isSetValue?: boolean;
  label: string;
  mode?: "multiple" | "tags";
  name: string;
  require?: boolean;
  onChange?: (selected: string | string[], option: OptionType | OptionType[]) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  formik,
  options,
  disabled,
  isSetValue = false,
  label,
  mode,
  name,
  require = true,
  onChange,
}) => {
  const filterOption = (input: string, option?: OptionType) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input.toLowerCase());
  const touched = !!formik.touched[name];
  const error = getErrorString(formik.errors[name]);
  return (
    <div>
      <LabelComponent value={label} require={require} />
      <Select
        mode={mode}
        className="w-full p-2 border border-gray-200 rounded mt-3"
        allowClear
        filterOption={filterOption}
        options={options}
        value={formik.values[name] || []}
        showSearch
        placeholder={` ${mode==='tags' ? "Type and ":""} Select ${label}`}
        disabled={disabled}
        onChange={(selected, option: OptionType | OptionType[]) => {
          if (isSetValue) {
            formik.setFieldValue(name, (option as OptionType)?.value);
          } else if (mode) {
            formik.setFieldValue(name, selected);
          } else if(onChange){
            onChange(selected, option);

          } else {
            formik.setFieldValue(name, (option as OptionType)?.label);
          }
        }}
        bordered={false}
      />
      <CustomFormikError
        name={name}
        touched={touched}
        error={error}
      />
    </div>
  );
};

export default CustomSelect;
