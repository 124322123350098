
import React from 'react';
import { UserAPI } from "../../../(apis)/(shared-apis)/user-apis";

const TopBar = ({ email, setEmail, loading, setLoading, setFetchUser , setUser, setBundles, setCourses, setOwnBundles }) => {

    const RetrieveUser = async () => {
        if (!email) {
            alert("Please enter email");
            return;
        }
        setLoading(true);
        const GetUser = await UserAPI("getUserByEmail", [""], { email: email });
        setUser(GetUser.data.data);
        const { featuresPurchased, courses_bundles, seperateComponents } = GetUser.data.data;
        setBundles(featuresPurchased);
        setCourses(courses_bundles);
        setOwnBundles(seperateComponents);
        setFetchUser(true);
        setLoading(false);
    };

    return (
        <div className="flex justify-start w-full md:w-[50%] mt-2 mb-5 border border-gray-300 rounded overflow-hidden">
            <input
                type="text"
                placeholder="Email"
                className="p-1 md:p-2 w-full focus:outline-none"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        RetrieveUser();
                    }
                }}
            />
            <button
                className="bg-rose-500 text-white p-1 md:p-2 w-[50%] md:w-[30%] font-bold hover:bg-rose-600 focus:outline-none transition duration-300 ease-in-out"
                onClick={RetrieveUser}
                disabled={loading}
            >
                {loading ? "Loading..." : "Retrieve User"}
            </button>
        </div>
    )
}


export default TopBar;