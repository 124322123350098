import { Dropdown, Input, Tabs } from "antd";

import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import React from "react";

const { Search } = Input;
interface StatusItem {
  key: string;
  label: React.ReactNode; // Change from JSX.Element to string
}

interface DropdownItem {
  label: React.ReactNode;
  key: string;
}

interface TableHeaderProps {
  statusList?: StatusItem[];
  handleChangeStatus: (key: string) => void;
  searchPlaceholder: string;
  searchText: string;
  handleSearch: (text: string) => void;
  showModal: () => void;
  items: DropdownItem[];
}
const TableHeader: React.FC<TableHeaderProps> = ({
  statusList,
  handleChangeStatus,
  searchPlaceholder,
  searchText,
  handleSearch,
  showModal,
  items,
}) => {
  return (
    <div>
      {statusList?.length > 0 ? (
        <div className="flex flex-row justify-between my-2">
          <div className="w-full ">
            <Tabs
              defaultActiveKey="1"
              items={statusList}
              onChange={handleChangeStatus}
              className="w-full"
            />
          </div>
          <div className="flex flex-col md:flex-row w-full gap-1">
        <div>
        <Search
              placeholder={searchPlaceholder}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full my-4 md:my-0 h-7 p-2 border border-gray-300 rounded-lg"
              bordered={false}
            />
        </div>
            <div className="flex w-full  flex-row justify-center items-center gap-4  mb-6 md:mb-0">
              <FilterOutlined
                className="cursor-pointer md:mt-0"
                onClick={showModal}
              />
              <Dropdown
                trigger={["click"]}
                arrow={true}
                menu={{ items }}
                placement="bottomRight"
              >
                <MenuOutlinedIcon className="cursor-pointer mt-5 md:mt-0" />
              </Dropdown>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-end items-end my-2">
          <div className="w-full md:w-1/2">
            <form className="flex items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <SearchOutlined className="text-gray-400" />
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full p-2 pl-10 h-7 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                  placeholder="Search"
                  required
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="flex w-full flex-row gap-4 ml-4 md:mb-0">
            <FilterOutlined
              className="cursor-pointer md:mt-0"
              onClick={showModal}
            />
            <Dropdown
              trigger={["click"]}
              arrow={true}
              menu={{ items }}
              placement="bottomRight"
            >
              <MenuOutlinedIcon className="cursor-pointer mt-5 md:mt-0" />
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableHeader;
