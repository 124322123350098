import React from 'react';

interface LabelComponentProps {
  value: string;
  require: boolean;
}

const LabelComponent: React.FC<LabelComponentProps> = ({ value, require }) => {
  return (
    <label className="text-md font-semibold text-gray-800 mb-2">
      {value} {require && <span className="text-red-500">*</span>}
    </label>
  );
}

export default LabelComponent;
